import React, { useEffect, useState } from "react";
import { Table } from "rsuite";
import swal from "sweetalert";
import { CreateBudgetItemForm } from "../..";
import {
  AllActiveStatusLocation,
  AllActiveStatusModeOfPayment,
  AllActiveStatusRequiredDocument,
  AllActiveStatusRequiredSubmittals,
  GetActiveFinalPaymentAgainst,
  GetActivePaymentProgress,
  GetAllActiveCountry,
  GetAllActiveIncoterm,
  GetAllActivePaymentTerms,
  GetAllActiveStage,
  GetAllActiveTransportation,
  GetAllItemsByVendor,
  GetItemReport,
  UpdateBudgetItem,
  getAllActiveVendor,
  getVendorByLineItem
} from "../../../axios";
import {
  Button,
  FormCheckBox,
  FormDropDown,
  FormTextInput,
} from "../../../components";
import SearchCheckedTables from "../../../components/AppSpecific/SearchTable/SearchCheckedTables";
import LoaderComponent from "../../../components/common/Loader";
import { colors } from "../../../configs";
import strings from "../../../translations";

const { Column, HeaderCell, Cell } = Table;
const EditableCell = ({ rowData, dataKey, onChange, ...props }) => {
  const editing = rowData.status === "EDIT";
  return (
    <Cell {...props} className={editing ? "table-content-editing" : ""}>
      {editing ? (
        <input
          className="rs-input"
          defaultValue={rowData[dataKey]}
          onChange={(event) => {
            onChange && onChange(rowData.item_id, dataKey, event.target.value);
          }}
        />
      ) : (
        <span className="table-content-edit-span">{rowData[dataKey]}</span>
      )}
    </Cell>
  );
};

const CreateBudgetItemPOForm = (props) => {
  const {
    setMessage,
    errorMessage,
    handleOrdersData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
    selectedItem,
    projectDetails,
    budget_id,
    proId,
  } = props;


 
  const mapableData = [
    {
      dataKey: "itemCtrlNo",
      title: "Ctrl No.",
    },
    {
      dataKey: "budget_quantity",
      title: "Budget Qty",
    },
    {
      dataKey: "budget_Price",
      title: "Budget Price",
    },
    {
      dataKey: "actual_quantity",
      title: "Actual Qty",
    },
    {
      dataKey: "actualPrice",
      title: "Actual Price",
    },
    {
      dataKey: "total_price",
      title: "Total Qty",
    },
    {
      dataKey: "unit_price",
      title: "Unit Price",
    },
  ];

  const [selectVendor, setSelectVendor] = useState({
    value: "",
    error: false,
    success: false,
  });
  const [selectItem, setSelectItem] = useState([]);

  const [isLoader, setIsLoader] = useState(false);
  const [purchaseOrdersName, setPurchaseOrdersName] = useState({
    value: initialValue.purchase_order_name
      ? initialValue.purchase_order_name
      : "",
    error: false,
    success: false,
  });
  const [vendor, setVendor] = useState({
    value: initialValue.vendor ? initialValue.vendor : "",
    error: false,
    success: false,
  });
  const [billingAddress, setBillingAddress] = useState({
    value: initialValue.billing_address
      ? initialValue.billing_address
      : projectDetails.billing_address
      ? projectDetails.billing_address
      : "",
    error: false,
    success: false,
  });
  const [shiptoVendor, setShiptoVendor] = useState({
    value: initialValue.ship_to_vendor ? initialValue.ship_to_vendor.id : "",
    error: false,
    success: false,
  });
  const [shippingAddress, setShippingAddress] = useState({
    value: initialValue.shipping_address
      ? initialValue.shipping_address
      : projectDetails.shipping_address
      ? projectDetails.shipping_address
      : "",
    error: false,
    success: false,
  });
  const [incoterms, setIncoterms] = useState({
    value: initialValue.incoterms_id ? initialValue.incoterms_id : "",
    error: false,
    success: false,
  });
  const [originType, setOriginType] = useState({
    value: initialValue.origin_type_id ? initialValue.origin_type_id : "",
    error: false,
    success: false,
  });
  const [originDetails, setOriginDetails] = useState({
    value: initialValue.origin_details ? initialValue.origin_details : "",
    error: false,
    success: false,
  });
  const [modeofTransportation, setModeofTransportation] = useState({
    value: initialValue.mode_of_transportation_id
      ? initialValue.mode_of_transportation_id
      : "",
    error: false,
    success: false,
  });
  const [deliveryLocation, setDeliveryLocation] = useState({
    value: initialValue.delivery_location_id
      ? initialValue.delivery_location_id
      : "",
    error: false,
    success: false,
  });
  const [reqrddoc, setReqrddoc] = useState([]);
  const [isItemForm, setIsItemForm] = useState(false);

  const [bankChargesBy, setBankChargesBy] = useState({
    value: initialValue.bank_charges_by_id
      ? initialValue.bank_charges_by_id
      : "",
    error: false,
    success: false,
  });
  const [modeOfPayment, setModeOfPayment] = useState({
    value: initialValue.mode_of_payment_id
      ? initialValue.mode_of_payment_id
      : "",
    error: false,
    success: false,
  });
  const [paymentTerms, setPaymentTerms] = useState([]);

  const [showAdvance, setShowAdvance] = useState(false);
  const [showBeforeShipment, setShowBeforeShipment] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [showFinal, setShowFinal] = useState(false);
  const [advPayment, setAdvPayment] = useState({
    value: initialValue.advPayment ? initialValue.advPayment : "",
    error: false,
    success: false,
  });
  const [progressPaymentPercentage, setProgressPaymentPercentage] = useState({
    value: initialValue.progressPayPercentage ? initialValue.progressPayPercentage : "",
    error: false,
    success: false,
  });
  const [advPayPercentage, setAdvPayPercentage] = useState({
    value: initialValue.advPayment ? initialValue.advPayment : "",
    error: false,
    success: false,
  });
  const [finalPayPercentage, setFinalPayPercentage] = useState({
    value: initialValue.finalPayPercentage
      ? initialValue.finalPayPercentage
      : "",
    error: false,
    success: false,
  });
  const [advancePaymentD1, setAdvancePaymentD1] = useState({
    value: initialValue.advancePaymentD1 ? initialValue.advancePaymentD1 : "",
    error: false,
    success: false,
  });
  const [advancePaymentD2, setAdvancePaymentD2] = useState({
    value: initialValue.advancePaymentD2 ? initialValue.advancePaymentD2 : "",
    error: false,
    success: false,
  });
  const [beforeShipmentPayment, setBeforeShipmentPayment] = useState({
    value: initialValue.beforeShipmentPayment
      ? initialValue.beforeShipmentPayment
      : "",
    error: false,
    success: false,
  });
  const [beforeShipmentPaymentD1, setBeforeShipmentPaymentD1] = useState({
    value: initialValue.beforeShipmentPaymentD1
      ? initialValue.beforeShipmentPaymentD1
      : "",
    error: false,
    success: false,
  });
  const [beforeShipmentPaymentD2, setBeforeShipmentPaymentD2] = useState({
    value: initialValue.beforeShipmentPaymentD2
      ? initialValue.beforeShipmentPaymentD2
      : "",
    error: false,
    success: false,
  });
  const [progressPayment, setProgressPayment] = useState({
    value: initialValue.progressPayment ? initialValue.progressPayment : "",
    error: false,
    success: false,
  });
  const [progressPaymentD1, setProgressPaymentD1] = useState({
    value: initialValue.progressPaymentD1 ? initialValue.progressPaymentD1 : "",
    error: false,
    success: false,
  });
  const [progressPaymentD2, setProgressPaymentD2] = useState({
    value: initialValue.progressPaymentD2 ? initialValue.progressPaymentD2 : "",
    error: false,
    success: false,
  });
  const [finalPayment, setFinalPayment] = useState({
    value: initialValue.finalPayment ? initialValue.finalPayment : "",
    error: false,
    success: false,
  });
  const [finalPaymentD1, setFinalPaymentD1] = useState({
    value: initialValue.finalPaymentD1 ? initialValue.finalPaymentD1 : "",
    error: false,
    success: false,
  });
  const [finalPaymentD2, setFinalPaymentD2] = useState({
    value: initialValue.finalPaymentD2 ? initialValue.finalPaymentD2 : "",
    error: false,
    success: false,
  });
  const [shopDrawing, setShopDrawing] = useState({
    value: initialValue.PurchaseOrderLeadTime
      ? initialValue.PurchaseOrderLeadTime[0].days_for_shop_drawing
      : "",
    error: false,
    success: false,
  });
  const [finishSample, setFinishSample] = useState({
    value: initialValue.PurchaseOrderLeadTime
      ? initialValue.PurchaseOrderLeadTime[0].days_for_finish_sample
      : "",
    error: false,
    success: false,
  });

  const [sample, setSample] = useState({
    value: initialValue.PurchaseOrderLeadTime
      ? initialValue.PurchaseOrderLeadTime[0].days_for_sample_prototype
      : "",
    error: false,
    success: false,
  });
  const [washTestSample, setWashTestSample] = useState({
    value: initialValue.PurchaseOrderLeadTime
      ? initialValue.PurchaseOrderLeadTime[0].days_for_wash_test_sample
      : "",
    error: false,
    success: false,
  });
  const [production, setProduction] = useState({
    value: initialValue.PurchaseOrderLeadTime
      ? initialValue.PurchaseOrderLeadTime[0].days_for_production
      : "",
    error: false,
    success: false,
  });
  const [transit, setTransit] = useState({
    value: initialValue.PurchaseOrderLeadTime
      ? initialValue.PurchaseOrderLeadTime[0].days_for_transit
      : "",
    error: false,
    success: false,
  });

  const [productionTriggers, setProductionTriggers] = useState([
    { key: 1, functionName: "Submittal Approvals", checked: false },
    { key: 2, functionName: "Advance Payment", checked: false },
  ]);

  const [transitTriggers, settransitTriggers] = useState([
    { key: 1, functionName: "Submittal Approvals", checked: false },
    { key: 2, functionName: "Pre Shipment Payment", checked: false },
  ]);

  const [rqrdSubmittalsCheck, setrqrdSubmittalsCheck] = useState([]);

  const [specialNotes, setSpecialNotes] = useState({
    value: initialValue.specialNotes ? initialValue.specialNotes : "",
    error: false,
    success: false,
  });
  const [paymentProgressOption, setPaymentProgressOption] = React.useState([]);
  const [paymentFinalOption, setPaymentFinalOption] = React.useState([]);
  const [skip, setSkip] = useState(false);
  const [data, setData] = useState([]);
  const [vendorDropDown, SetvendorDropDown] = useState([]);
  const [vendorDropDownLineItem, setvendorDropDownLineItem] = useState([]);
  const [incotermDropDown, SetincotermDropDown] = useState([]);
  const [countryDropDown, SetcountryDropDown] = useState([]);
  const [MOTDropDown, SetMOTDropDown] = useState([]);
  const [locationDropDown, SetlocationDropDown] = useState([]);
  const [ModeofpaymentDropDown, SetModeofpaymentDropDown] = useState([]);
  const [screen, setScreen] = React.useState(0);
  const [vendorError, setVendorError] = React.useState("");
  const [btnDisable, setBtnDisable] = React.useState(true);
  const [budgetPrice, setBudgetPrice] = React.useState(0);

  const functionPaymentResultTrue = (paymentTerms) => {
    const paymentArray = [];
    let objectdata = {};
    paymentTerms.forEach((func) => {
      if (func.checked === true) {
        objectdata = {
          purchase_order_payment_term_id: func.key.toString(),
          value:
            func.key === 1
              ? advPayment.value
              : func.key === 2
              ? beforeShipmentPayment.value
              : func.key === 3
              ? progressPayment.value
              : finalPayment.value,
          option_one:
            func.key === 1
              ? advancePaymentD1.value
              : func.key === 2
              ? beforeShipmentPaymentD1.value
              : func.key === 3
              ? progressPaymentD1.value
              : finalPaymentD1.value,
          option_two:
            func.key === 1
              ? advancePaymentD2.value
              : func.key === 2
              ? beforeShipmentPaymentD2.value
              : func.key === 3
              ? progressPaymentD2.value
              : finalPaymentD2.value,
          percentage:
            func.key === 1
              ? advPayPercentage.value
              : func.key === 2
              ? beforeShipmentPayment.value
              : func.key === 3
              ? progressPaymentPercentage.value
              : finalPayPercentage.value,
        };
        paymentArray.push(objectdata);
      }
    });
    return paymentArray;
  };
  const functionRequiredDocumentTrue = (reqrddoc) => {
    const requiredDocumentArray = [];
    reqrddoc.forEach((func) => {
      if (func.checked === true) {
        requiredDocumentArray.push(func.key);
      }
    });
    return requiredDocumentArray;
  };
  const functionRequiredSubmittalsTrue = (rqrdSubmittalsCheck) => {
    const requiredSubmittalsArray = [];
    rqrdSubmittalsCheck.forEach((func) => {
      if (func.checked === true) {
        requiredSubmittalsArray.push(func.key);
      }
    });
    return requiredSubmittalsArray;
  };
  const functionProductionTrue = (productionTriggers) => {
    const productionTriggersArray = [];
    productionTriggers.forEach((func) => {
      if (func.checked === true) {
        productionTriggersArray.push(func.key);
      }
    });
    return productionTriggersArray;
  };
  const functionTransitTrue = (transitTriggers) => {
    const transitTriggersArray = [];

    transitTriggers.forEach((func) => {
      if (func.checked === true) {
        transitTriggersArray.push(func.key);
      }
    });
    return transitTriggersArray;
  };
  const handleChange = (id, key, value) => {
    const nextData = Object.assign([], data);
    nextData.find((item) => item.id === id)[key] = value;
    setData(nextData);
  };
  const getPurchaseItems = () => {
    const budgetItemList = selectedItem.map((elem) => ({
      id: elem.id,
      itemCtrlNo: elem.control_number,
      itemDesc: elem.name,
      budget_quantity: elem.budgetquantitydetails?.budget_quantity
        ? elem.budgetquantitydetails?.budget_quantity
        : 0,
      budget_Price: elem.budgetquantitydetails?.budget_price
        ? elem.budgetquantitydetails?.budget_price
        : 0,
      actual_quantity: elem.budgetquantitydetails?.actual_quantity
        ? elem.budgetquantitydetails.actual_quantity
        : 0,
      actualPrice: elem.budgetquantitydetails?.actual_price
        ? elem.budgetquantitydetails.actual_price
        : 0,
      actual_uom_id: elem.budgetquantitydetails?.actual_uom_id
        ? elem.budgetquantitydetails.actual_uom_id
        : elem.budgetquantitydetails?.budget_uom_id,
      // quantity: elem.budgetquantitydetails?.actual_quantity
      //   ? elem.budgetquantitydetails.actual_quantity
      //   : elem.budgetquantitydetails?.budget_quantity,
       quantity: elem.budgetquantitydetails?.actual_quantity
        ? elem.budgetquantitydetails.actual_quantity
        : 0,
      total_price:
        elem.budgetquantitydetails.actual_price !== null
          ? (elem.budgetquantitydetails?.actual_price
              ? elem.budgetquantitydetails?.actual_price
              : 0
            ).toFixed(2)
          : 0,
      // unit_price:
      //   elem.budgetquantitydetails.actual_price !== null
      //     ? (elem.budgetquantitydetails?.actual_price
      //         ? elem?.budgetquantitydetails?.actual_price /
      //           elem.budgetquantitydetails?.actual_quantity
      //         : elem.budgetquantitydetails.budget_price /
      //           elem.budgetquantitydetails.budget_quantity
      //       ).toFixed(2)
      //     : 0,
      unit_price:
      elem.budgetquantitydetails.actual_price !== null
        ? (elem.budgetquantitydetails?.actual_price
            ? elem?.budgetquantitydetails?.actual_price 
            : 0
          ).toFixed(2)
        : 0,
      quotation_id: null,
      vendorId:
        elem.budget_vendordetails !== null
          ? elem.budget_vendordetails?.vendordata?.id
          : null,
    }));
    let isDifferent = false;
    budgetItemList.forEach((item) => {
      for (let i of budgetItemList) {
        if (i.vendorId === null) {
          setVendorError("Vendor name required");
          isDifferent = true;
        } else {
          for (let i of budgetItemList) {
            if (item.vendorId !== i.vendorId) {
              setVendorError("Select a single vendor name");
              isDifferent = true;
            }
          }
        }
      }
    });
    // setVendor({
    //   value: budgetItemList[0].vendorId,
    //   error: false,
    //   success: false,
    // })
    // const budgetPrice = budgetItemList.reduce((acc, curr) => {
    //   return acc + parseFloat(curr.actualPrice)
    // }, 0).toFixed(2)
    // setBudgetPrice(budgetPrice)
    // setBtnDisable(isDifferent)
    // setRows(budgetItemList);
  };

  const getAllActivePaymentProgress = () => {
    setIsLoader(true);
    GetActivePaymentProgress()
      .then((res) => {
        let data = res.data.data;
        const result = data.map((ele) => ({
          key: ele.id,
          value: ele.name,
          label: ele.name,
        }));
        setIsLoader(false);
        setPaymentProgressOption(result);
      })
      .catch((e) => {
        setIsLoader(false);
      });
  };

  const getAllActivePaymentFinal = () => {
    setIsLoader(true);
    GetActiveFinalPaymentAgainst()
      .then((res) => {
        let data = res.data.data;
        const result = data.map((ele) => ({
          key: ele.id,
          value: ele.name,
          label: ele.name,
        }));
        setIsLoader(false);
        setPaymentFinalOption(result);
      })
      .catch((e) => {
        setIsLoader(false);
      });
  };

  React.useEffect(() => {
    getAllActivePaymentProgress();
    getAllActivePaymentFinal();
  }, []);

  const GetAllActiveVendor = () => {
    setIsLoader(true);
    getAllActiveVendor()
      .then((res) => {
        let data = res.data.data;
        const result = data.map((ele) => ({
          key: ele.id,
          value: ele.id,
          label: ele.name,
        }));
        setIsLoader(false);
        SetvendorDropDown(result);
      })
      .catch((e) => {
        setIsLoader(false);
      });
  };
  const GetAllVendorFromBudget = (budget_id) => {
    setIsLoader(true);
    getVendorByLineItem(budget_id)
      .then((res) => {
        let data = res.data.data;
        const result = data.map((ele) => ({
          key: ele.id,
          value: ele.id,
          label: ele.name,
        }));
        setIsLoader(false);
        setvendorDropDownLineItem(result);
      })
      .catch((e) => {
        setIsLoader(false);
      });
  };

  const getAllActiveIncoterm = () => {
    setIsLoader(true);
    GetAllActiveIncoterm()
      .then((res) => {
        let data = res.data.data;
        const result = data.map((ele) => ({
          key: ele.id,
          value: ele.id,
          label: ele.name,
        }));
        setIsLoader(false);
        SetincotermDropDown(result);
      })
      .catch((e) => {
        setIsLoader(false);
      });
  };

  const getAllActiveCountry = () => {
    setIsLoader(true);
    GetAllActiveCountry()
      .then((res) => {
        let data = res.data.data;
        const result = data.map((ele) => ({
          key: ele.id,
          value: ele.id,
          label: ele.name,
        }));
        setIsLoader(false);
        SetcountryDropDown(result);
      })
      .catch((e) => {
        setIsLoader(false);
      });
  };

  const getAllActiveMot = () => {
    setIsLoader(true);
    GetAllActiveTransportation()
      .then((res) => {
        let data = res.data.data;
        const result = data.map((ele) => ({
          key: ele.id,
          value: ele.id,
          label: ele.name,
        }));
        setIsLoader(false);
        SetMOTDropDown(result);
      })
      .catch((e) => {
        setIsLoader(false);
      });
  };

  const getAllActiveLocation = () => {
    setIsLoader(true);
    AllActiveStatusLocation()
      .then((res) => {
        let data = res.data.data;
        const result = data.map((ele) => ({
          key: ele.id,
          value: ele.id,
          label: ele.name,
        }));
        setIsLoader(false);
        SetlocationDropDown(result);
      })
      .catch((e) => {
        setIsLoader(false);
      });
  };

  useEffect(() => {
    setIsLoader(true);
    AllActiveStatusRequiredDocument()
      .then((res) => {
        let data = res.data.data;
        const result = data.map((ele) => ({
          key: ele.id,
          value: ele.id,
          label: ele.name,
        }));
        setIsLoader(false);
        setReqrddoc(result);
      })
      .catch((e) => {
        setIsLoader(false);
      });
  }, [actionType]);

  const getAllActiveMOP = () => {
    setIsLoader(true);
    AllActiveStatusModeOfPayment()
      .then((res) => {
        let data = res.data.data;
        const result = data.map((ele) => ({
          key: ele.id,
          value: ele.id,
          label: ele.name,
        }));
        setIsLoader(false);
        SetModeofpaymentDropDown(result);
      })
      .catch((e) => {
        setIsLoader(false);
      });
  };

  const getAllActivePaymentTerms = () => {
    setIsLoader(true);
    GetAllActivePaymentTerms()
      .then((res) => {
        let data = res.data.data;
        const result = data.map((ele) => ({
          key: ele.id,
          value: ele.id,
          label: ele.name,
        }));
        setIsLoader(false);
        setPaymentTerms(result);
      })
      .catch((e) => {
        setIsLoader(false);
      });
  };

  useEffect(() => {
    if (actionType === "add") {
      setIsLoader(true);
      AllActiveStatusRequiredSubmittals()
        .then((res) => {
          let data = res.data.data;
          const result = data.map((ele) => ({
            key: ele.id,
            value: ele.id,
            label: ele.name,
          }));
          setIsLoader(false);
          setrqrdSubmittalsCheck(result);
        })
        .catch((e) => {
          setIsLoader(false);
        });
    }
  }, [actionType]);

  useEffect(() => {
    getPurchaseItems();
    GetAllActiveVendor();
    getAllActiveIncoterm();
    getAllActiveCountry();
    getAllActiveMot();
    getAllActiveLocation();
    getAllActiveMOP();
    getAllActivePaymentTerms();
    GetAllVendorFromBudget(budget_id)
  }, []);

  const [uid, setUid] = React.useState(null);

  React.useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    setUid(user.id);
  }, []);

  console.log({uid});

  const handleSaveData = () => {
    setVendorError("");
    let newItem = [];
    rows.forEach((ele) => {
      const result = {
        quotation_id: 0,
        budget_item_id: ele.id.toString(),
        actual_uom_id:
          ele.actual_uom_id !== null ? ele.actual_uom_id.toString() : "0",
        actual_quantity: ele.quantity,
        total_price: ele.total_price.toString(),
        unit_price: ele.unit_price.toString(),
      };

      if (ele.checked) {
        newItem.push(result);
      }
    });


    const purchaseOrdersData = {
      project_id: proId,
      item_details: newItem,
      rfq_id: 0,
      purchase_order_name: purchaseOrdersName.value,
      ship_to_vendor_id: shiptoVendor.value !== "" ? shiptoVendor.value : 0,
      vendor_id: vendor.value,
      billing_address: billingAddress.value,
      shipping_address: shippingAddress.value,
      purchase_order_status: "Draft",
      quotation_numbers: [],
      incoterms_id: incoterms.value,
      origin_type_id: originType.value,
      origin_details: originDetails.value,
      mode_of_transportation_id: modeofTransportation.value,
      delivery_location_id: deliveryLocation.value.toString(),
      bank_charges_by_id: bankChargesBy.value,
      mode_of_payment_id: modeOfPayment.value,
      purchase_order_required_document: functionRequiredDocumentTrue(reqrddoc),
      notes: specialNotes ? specialNotes.value : null,
      purchase_order_payment_term: functionPaymentResultTrue(paymentTerms),
      lead_time: [
        {
          days_for_shop_drawing: shopDrawing.value?.toString(),
          days_for_sample_prototype: sample.value?.toString(),
          days_for_finish_sample: finishSample.value?.toString(),
          days_for_wash_test_sample: washTestSample.value?.toString(),
          days_for_production: production.value?.toString(),
          days_for_transit: transit.value?.toString(),
        },
      ],
      production_triggers: functionProductionTrue(productionTriggers),
      transit_triggers: functionTransitTrue(transitTriggers),
      required_submittals: functionRequiredSubmittalsTrue(rqrdSubmittalsCheck),
      file_data: null,
      purchase_order_item_size: [{ height: "1", width: "1", length: "1" }],
      action_by: uid,
    };
    handleOrdersData(purchaseOrdersData);
  };

  const handleChangeVendor = (value) => {
    setMessage(null);
    setVendorError("");
    setVendor(value);
  };
  const handleChangebillingAddress = (value) => {
    setMessage(null);
    setVendorError("");
    setBillingAddress(value);
  };
  const handleChangeshiptoVendor = (value) => {
    setMessage(null);
    setVendorError("");
    setShiptoVendor(value);
  };
  const handleChangeshippingAddress = (value) => {
    setShippingAddress(value);
  };
  const nextBtnClick = () => {
    setScreen(screen + 1);
  };
  const prevBtnClick = () => {
    setScreen(screen - 1);
  };
  const handleChangeIncoterms = (value) => {
    setIncoterms(value);
  };
  const handleChangeOriginType = (value) => {
    setOriginType(value);
  };
  const handleChangeoriginDetails = (value) => {
    setOriginDetails(value);
  };
  const handleChangemodeofTransportation = (value) => {
    setModeofTransportation(value);
  };
  const handleChangedeliveryLocation = (value) => {
    setDeliveryLocation(value);
  };
  const handleChangeCheck = (key) => {
    const result = reqrddoc.map((functionName) => {
      if (functionName.key === key) {
        return {
          ...functionName,
          checked: !functionName.checked,
        };
      } else {
        return {
          ...functionName,
        };
      }
    });
    setReqrddoc(result);
  };
  const handleChangePaymentTerms = (key) => {
    const paymentResult = paymentTerms.map((functionName) => {
      if (functionName.key === key) {
        return {
          ...functionName,
          checked: !functionName.checked,
        };
      } else {
        return {
          ...functionName,
        };
      }
    });
    setPaymentTerms(paymentResult);
    if (key === 1) {
      setShowAdvance(!showAdvance);
    }
    if (key === 2) {
      setShowBeforeShipment(!showBeforeShipment);
    }
    if (key === 3) {
      setShowProgress(!showProgress);
    }
    if (key === 4) {
      setShowFinal(!showFinal);
    }
  };
  const handleCheckBoxChange = () => {
    if (showAdvance === false) {
      setAdvPayment("");
      setAdvancePaymentD1("");
      setAdvancePaymentD2("");
    }
    if (showBeforeShipment === false) {
      setBeforeShipmentPayment("");
      setBeforeShipmentPaymentD1("");
      setBeforeShipmentPaymentD2("");
    }
    if (showProgress === false) {
      setProgressPayment("");
      setProgressPaymentD1("");
      setProgressPaymentD2("");
    }
    if (showFinal === false) {
      setFinalPayment("");
      setFinalPaymentD1("");
      setFinalPaymentD2("");
    }
  };
  const handleChangeProductionTriggers = (key) => {
    const resultProductionTriggers = productionTriggers.map((functionName) => {
      if (functionName.key === key) {
        return {
          ...functionName,
          checked: !functionName.checked,
        };
      } else {
        return {
          ...functionName,
        };
      }
    });
    setProductionTriggers(resultProductionTriggers);
  };
  const handleChangeTransitTriggers = (key) => {
    const resultTransitTriggers = transitTriggers.map((functionName) => {
      if (functionName.key === key) {
        return {
          ...functionName,
          checked: !functionName.checked,
        };
      } else {
        return {
          ...functionName,
        };
      }
    });
    settransitTriggers(resultTransitTriggers);
  };
  const handleChangebankChargesBy = (value) => {
    setBankChargesBy(value);
  };
  const handleChangemodeOfPayment = (value) => {
    setModeOfPayment(value);
  };
  const handleChangeAdvPayment = (value) => {
    // let paymentValue = value.value !== "" ? parseInt(value.value) : 0;
    // let totalValue = budgetPrice;
    // let PercentageAdvVal = ((paymentValue / totalValue) * 100).toFixed(2);
    // setAdvPayPercentage({
    //   value: PercentageAdvVal,
    //   error: false,
    //   success: false,
    // });
    setAdvPayment(value);
  };
  const handleChangeAdvPayPercentage = (value) => {
    let totalValue = budgetPrice;
    let paymentPercentage = value.value !== "" ? parseInt(value.value) : 0;
    let paymentValue = (paymentPercentage * totalValue) / 100;
    setAdvPayPercentage(value);
    setAdvPayment({
      value: paymentValue,
      error: false,
      success: false,
    });
  };
  const handleChangeFinalPayPercentage = (value) => {
    let totalValue = budgetPrice;
    let paymentPercentage = value.value !== "" ? parseInt(value.value) : 0;
    let paymentValue = (paymentPercentage * totalValue) / 100;
    setFinalPayPercentage(value);
    setFinalPayment({
      value: paymentValue,
      error: false,
      success: false,
    });
  };
  const handleChangeadvancePaymentD1 = (value) => {
    setAdvancePaymentD1(value);
  };
  const handleChangeadvancePaymentD2 = (value) => {
    setAdvancePaymentD2(value);
  };
  const handleChangeBeforeShipmentPayment = (value) => {
    setBeforeShipmentPayment(value);
  };
  const handleChangebeforeShipmentPaymentD1 = (value) => {
    setBeforeShipmentPaymentD1(value);
  };
  const handleChangebeforeShipmentPaymentD2 = (value) => {
    setBeforeShipmentPaymentD2(value);
  };
  const handleChangeprogressPayment = (value) => {
    // let totalValue = budgetPrice;
    // let paymentPercentage = value.value !== "" ? parseInt(value.value) : 0;
    // let paymentValue = (paymentPercentage * totalValue) / 100;
    // setProgressPaymentPercentage({
    //   value: paymentValue,
    //   error: false,
    //   success: false,
    // });
    setProgressPayment(value);
  };
  const handleChangeprogressPaymentD1 = (value) => {
    
    setProgressPaymentD1(value);
  };
  const handleChangeprogressPaymentD2 = (value) => {
  
    setProgressPaymentD2(value);
  };
  const handleChangefinalPayment = (value) => {
    // let paymentValue = value.value !== "" ? parseInt(value.value) : 0;
    // let totalValue = budgetPrice;
    // let PercentageAdvVal = ((paymentValue / totalValue) * 100).toFixed(2);
    // setFinalPayPercentage({
    //   value: PercentageAdvVal,
    //   error: false,
    //   success: false,
    // });
    setFinalPayment(value);
  };
  const handleChangefinalPaymentD1 = (value) => {
    setFinalPaymentD1(value);
  };
  const handleChangefinalPaymentD2 = (value) => {
    setFinalPaymentD2(value);
  };
  const handleChangeshopDrawing = (value) => {
    setShopDrawing(value);
  };
  const handleChangefinishSample = (value) => {
    setFinishSample(value);
  };
  const handleChangeSample = (value) => {
    setSample(value);
  };
  const handleChangeWashTestSample = (value) => {
    setWashTestSample(value);
  };
  const handleChangeTransit = (value) => {
    setTransit(value);
  };
  const handleChangeProduction = (value) => {
    setProduction(value);
  };
  const handleChangeRqrdSubmittalsCheck = (key) => {
    const resultSubmittalCheck = rqrdSubmittalsCheck.map((functionName) => {
      if (functionName.key === key) {
        return {
          ...functionName,
          checked: !functionName.checked,
        };
      } else {
        return {
          ...functionName,
        };
      }
    });
    setrqrdSubmittalsCheck(resultSubmittalCheck);
  };
  const handleChangespecialNotes = (value) => {
    setSpecialNotes(value);
  };
  let isValueNotChanged1 =
    vendor.value === initialValue.vendor &&
    purchaseOrdersName.value === initialValue.purchaseOrdersName &&
    billingAddress.value === initialValue.billingAddress &&
    shiptoVendor.value === initialValue.shiptoVendor &&
    shippingAddress.value === initialValue.shippingAddress &&
    incoterms.value === initialValue.incoterms &&
    originType.value === initialValue.originType &&
    originDetails.value === initialValue.originDetails &&
    modeofTransportation.value === initialValue.modeofTransportation &&
    deliveryLocation.value === initialValue.deliveryLocation &&
    bankChargesBy.value === initialValue.bankChargesBy &&
    modeOfPayment.value === initialValue.modeOfPayment &&
    production.value === initialValue.production &&
    transit.value === initialValue.transit ;

  let disable1 =
    vendor.error ||
    vendor.value === "" ||
    // billingAddress.error ||
    // billingAddress.value === "" ||
    // shippingAddress.value === "" ||
    incoterms.error ||
    incoterms.value === "" ||
    deliveryLocation.error ||
    deliveryLocation.value === "" ||
    bankChargesBy.value === "" ||
    modeOfPayment.value === "" ||
    bankChargesBy.error ||
    modeOfPayment.error ||
    production.error ||
    production.value === "" ||
    transit.error ||
    transit.value === "" || advancePaymentD1.value === "" ||
    advancePaymentD2.value ===  "" ||
    progressPaymentD1.value  === "" ||
    progressPaymentD2.value  === "" ||
    finalPaymentD1.value  === "" ||
    finalPaymentD2.value  === ""||
    isValueNotChanged1;
  const bankChargesByOption = [
    {
      key: 1,
      value: 1,
      label: "Vendor",
    },
    {
      key: 2,
      value: 2,
      label: "Buyer",
    },
  ];
  const paymentDropdown1 = [
    {
      key: 1,
      value: "Against",
      label: "Against",
    },
    {
      key: 2,
      value: "After",
      label: "After",
    },
    {
      key: 3,
      value: "30 Days after",
      label: "30 Days after",
    },
    {
      key: 4,
      value: "Prior to",
      label: "Prior to",
    },
  ];
  const paymentDropdown2 = [
    {
      key: 1,
      value: "Invoice",
      label: "Invoice",
    },
    {
      key: 2,
      value: "Original Invoice",
      label: "Original Invoice",
    },
    {
      key: 3,
      value: "Original Invoice & Bank Guarantee",
      label: "Original Invoice & Bank Guarantee",
    },
    {
      key: 4,
      value: "Original Invoice & Security Cheque",
      label: "Original Invoice & Security Cheque",
    },
  ];
  const createPurchaseOrdersHeader =
      strings["PurchaseOrders.createPurchaseOrdersHeader"],
    editPurchaseOrdersHeader =
      strings["PurchaseOrders.editPurchaseOrdersHeader"],
    purchaseOrderNameLabel = strings["PurchaseOrders.purchaseOrderNameLabel"],
    purchaseOrderNamePlaceholder =
      strings["PurchaseOrders.purchaseOrderNamePlaceholder"],
    purchaseOrderNameCustomError =
      strings["PurchaseOrders.purchaseOrderNameCustomError"],
    vendorLabel = strings["PurchaseOrders.vendorLabel"],
    vendorErrorLine = strings["PurchaseOrders.vendorErrorLine"],
    billingAddressLabel = strings["PurchaseOrders.billingAddressLabel"],
    billingAddressPlaceholder =
      strings["PurchaseOrders.billingAddressPlaceholder"],
    billingAddressCustomError =
      strings["PurchaseOrders.billingAddressCustomError"],
    shiptoVendorLabel = strings["PurchaseOrders.shiptoVendorLabel"],
    shiptoVendorErrorLine = strings["PurchaseOrders.shiptoVendorErrorLine"],
    shiptoVendorSelectText = strings["PurchaseOrders.shiptoVendorSelectText"],
    shippingAddressLabel = strings["PurchaseOrders.shippingAddressLabel"],
    shippingAddressPlaceholder =
      strings["PurchaseOrders.shippingAddressPlaceholder"],
    shippingAddressCustomError =
      strings["PurchaseOrders.shippingAddressCustomError"],
    incotermsLabel = strings["PurchaseOrders.incotermsLabel"],
    incotermsErrorLine = strings["PurchaseOrders.incotermsErrorLine"],
    incotermsSelectText = strings["PurchaseOrders.incotermsSelectText"],
    originTypeLabel = strings["PurchaseOrders.originTypeLabel"],
    originTypeErrorLine = strings["PurchaseOrders.originTypeErrorLine"],
    originTypeSelectText = strings["PurchaseOrders.originTypeSelectText"],
    originDetailsLabel = strings["PurchaseOrders.originDetailsLabel"],
    originDetailsPlaceholder =
      strings["PurchaseOrders.originDetailsPlaceholder"],
    originDetailsCustomError =
      strings["PurchaseOrders.originDetailsCustomError"],
    modeofTransportationLabel =
      strings["PurchaseOrders.modeofTransportationLabel"],
    modeofTransportationErrorLine =
      strings["PurchaseOrders.modeofTransportationErrorLine"],
    modeofTransportationSelectText =
      strings["PurchaseOrders.modeofTransportationSelectText"],
    deliveryLocationLabel = strings["PurchaseOrders.deliveryLocationLabel"],
    deliveryLocationErrorLine =
      strings["PurchaseOrders.deliveryLocationErrorLine"],
    deliveryLocationSelectText =
      strings["PurchaseOrders.deliveryLocationSelectText"],
    bankChargesByLabel = strings["PurchaseOrders.bankChargesByLabel"],
    bankChargesByErrorLine = strings["PurchaseOrders.bankChargesByErrorLine"],
    bankChargesBySelectText = strings["PurchaseOrders.bankChargesBySelectText"],
    modeOfPaymentLabel = strings["PurchaseOrders.modeOfPaymentLabel"],
    modeOfPaymentErrorLine = strings["PurchaseOrders.modeOfPaymentErrorLine"],
    modeOfPaymentSelectText = strings["PurchaseOrders.modeOfPaymentSelectText"],
    advancePaymentHeader = strings["PurchaseOrders.advancePaymentHeader"],
    paymentValueLabel = strings["PurchaseOrders.paymentValueLabel"],
    advPaymentPlaceholder = strings["PurchaseOrders.advPaymentPlaceholder"],
    advPaymentCustomError = strings["PurchaseOrders.advPaymentCustomError"],
    advancePaymentD1SelectText =
      strings["PurchaseOrders.advancePaymentD1SelectText"],
    advancePaymentD1ErrorLine =
      strings["PurchaseOrders.advancePaymentD1ErrorLine"],
    advancePaymentD2SelectText =
      strings["PurchaseOrders.advancePaymentD2SelectText"],
    advancePaymentD2ErrorLine =
      strings["PurchaseOrders.advancePaymentD2ErrorLine"],
    beforeShipmentPaymentHeader =
      strings["PurchaseOrders.beforeShipmentPaymentHeader"],
    beforeShipmentPaymentPlaceholder =
      strings["PurchaseOrders.beforeShipmentPaymentPlaceholder"],
    beforeShipmentPaymentCustomError =
      strings["PurchaseOrders.beforeShipmentPaymentCustomError"],
    beforeShipmentPaymentD1ErrorLine =
      strings["PurchaseOrders.beforeShipmentPaymentD1ErrorLine"],
    beforeShipmentPaymentD1SelectText =
      strings["PurchaseOrders.beforeShipmentPaymentD1SelectText"],
    beforeShipmentPaymentD2ErrorLine =
      strings["PurchaseOrders.beforeShipmentPaymentD2ErrorLine"],
    beforeShipmentPaymentD2SelectText =
      strings["PurchaseOrders.beforeShipmentPaymentD2SelectText"],
    progressPaymentHeader = strings["PurchaseOrders.progressPaymentHeader"],
    progressPaymentPlaceholder =
      strings["PurchaseOrders.progressPaymentPlaceholder"],
    progressPaymentCustomError =
      strings["PurchaseOrders.progressPaymentCustomError"],
    progressPaymentD1ErrorLine =
      strings["PurchaseOrders.progressPaymentD1ErrorLine"],
    progressPaymentD1SelectText =
      strings["PurchaseOrders.progressPaymentD1SelectText"],
    progressPaymentD2ErrorLine =
      strings["PurchaseOrders.progressPaymentD2ErrorLine"],
    progressPaymentD2SelectText =
      strings["PurchaseOrders.progressPaymentD2SelectText"],
    finalPaymentHeader = strings["PurchaseOrders.finalPaymentHeader"],
    finalPaymentPlaceholder = strings["PurchaseOrders.finalPaymentPlaceholder"],
    finalPaymentCustomError = strings["PurchaseOrders.finalPaymentCustomError"],
    finalPaymentD1ErrorLine = strings["PurchaseOrders.finalPaymentD1ErrorLine"],
    finalPaymentD1SelectText =
      strings["PurchaseOrders.finalPaymentD1SelectText"],
    finalPaymentD2ErrorLine = strings["PurchaseOrders.finalPaymentD2ErrorLine"],
    finalPaymentD2SelectText =
      strings["PurchaseOrders.finalPaymentD2SelectText"],
    shopDrawingLabel = strings["PurchaseOrders.shopDrawingLabel"],
    shopDrawingPlaceholder = strings["PurchaseOrders.shopDrawingPlaceholder"],
    shopDrawingCustomError = strings["PurchaseOrders.shopDrawingCustomError"],
    finishSampleLabel = strings["PurchaseOrders.finishSampleLabel"],
    finishSamplePlaceholder = strings["PurchaseOrders.finishSamplePlaceholder"],
    finishSampleCustomError = strings["PurchaseOrders.finishSampleCustomError"],
    sampleLabel = strings["PurchaseOrders.sampleLabel"],
    samplePlaceholder = strings["PurchaseOrders.samplePlaceholder"],
    sampleCustomError = strings["PurchaseOrders.sampleCustomError"],
    washTestSampleLabel = strings["PurchaseOrders.washTestSampleLabel"],
    washTestSamplePlaceholder =
      strings["PurchaseOrders.washTestSamplePlaceholder"],
    washTestSampleCustomError =
      strings["PurchaseOrders.washTestSampleCustomError"],
    productionLabel = strings["PurchaseOrders.productionLabel"],
    productionPlaceholder = strings["PurchaseOrders.productionPlaceholder"],
    productionCustomError = strings["PurchaseOrders.productionCustomError"],
    transitLabel = strings["PurchaseOrders.transitLabel"],
    transitPlaceholder = strings["PurchaseOrders.transitPlaceholder"],
    transitCustomError = strings["PurchaseOrders.transitCustomError"],
    ProductionTriggersHeader =
      strings["PurchaseOrders.ProductionTriggersHeader"],
    TransitTriggersHeader = strings["PurchaseOrders.TransitTriggersHeader"],
    specialNotesLabel = strings["PurchaseOrders.specialNotesLabel"],
    specialNotesPlaceholder = strings["PurchaseOrders.specialNotesPlaceholder"],
    specialNotesCustomError = strings["PurchaseOrders.specialNotesCustomError"],
    cancelBtn = strings["PurchaseOrders.cancelBtn"];

  const [rows, setRows] = React.useState([]);
  const [scheduleReport, setScheduleReport] = React.useState([]);

  const [itemId, setItemId] = React.useState(null);
  const [selectedItems, setSelectedItems] = React.useState([]);


  const updateBudgetLineItem = (formData) => {
    const id = itemId;
    setIsLoader(true);
    UpdateBudgetItem(formData, id)
      .then((res) => {
        getPurchaseItems();
        setIsLoader(false);
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
        setIsItemForm(false);
      })
      .catch((e) => {
        setIsLoader(false);
        setMessage(e.response.data.message);
      });
  };

  const handleModalClosed = () => {
    setMessage(null);
    setIsItemForm(false);
  };
  const getItemsByVendor = () => {
    let data = {
      budget_id: budget_id,
      vendor_id: selectVendor.value,
    };
    setIsLoader(true);
    GetAllItemsByVendor(data, data.vendor_id) 
    
      .then((res) => {
        const budgetItemList = res.data.data?.map((elem) => ({
          id: elem.item_serial_number,
          itemCtrlNo: elem.control_number,
          itemDesc: elem.name,
          budget_quantity:
            elem.budget_quantity !== null ? elem.budget_quantity : 0,
          budget_Price: elem.budget_price !== null ? elem.budget_price : 0,
          actual_quantity:
            elem.actual_quantity !== null ? elem.actual_quantity : 0,
          actualPrice: elem.actual_price !== null ? elem.actual_price : 0,
          actual_uom_id:
            elem?.actual_uom_id !== null
              ? elem.actual_uom_id
              : elem?.budget_uom_id,
          quantity:
            elem?.actual_quantity !== null
              ? elem.actual_quantity ? elem.actual_quantity : 0
              : 0,
          total_price:
            elem.actual_price !== null
              ? (elem?.actual_price
                  ? parseFloat(elem?.actual_price * elem?.actual_quantity)
                  : 0
                ).toFixed(2)
              : 0,
          // unit_price:
          //   elem.actual_price !== null
          //     ? (elem.actual_price
          //         ? parseFloat(elem.actual_price)
          //         : parseFloat(elem.budget_price)
          //       ).toFixed(2)
          //     : 0,
          unit_price:
            elem.actual_price !== null
              ? (elem.actual_price
                  ? parseFloat(elem.actual_price)
                  : 0
                ).toFixed(2)
              : 0,
          quotation_id: null,
          vendorId: selectVendor.value,
        }));
        const budgetPrice = budgetItemList
          .reduce((acc, curr) => {
            return acc + parseFloat(curr.actualPrice);
          }, 0)
          .toFixed(2);
        setBudgetPrice(budgetPrice);
        setIsLoader(false);
        setRows(budgetItemList);
        setScreen(1);
      })
      .catch((err) => {
        setIsLoader(false);
      });
  };
  const handleSelectVendor = (value) => {
    setSelectVendor(value);
  };

  const handleSelectData = (id) => {
    const result = rows.map((data) => {
      if (data.id === id) {
        return { ...data, checked: !data.checked };

      } else {
        return { ...data };
      }
    });
    setRows(result);
    setSelectItem(result);
    setVendor({
      value: selectVendor.value,
      error: false,
      success: false,
    });
  };

  const handleSelectAllData = () => {
    const allSelected = rows.every((item) => item.checked);
    const updatedItems = rows.map((item) => ({
      ...item,
      checked: !allSelected,
    }));
    setRows(updatedItems);
  
    const selected = !allSelected ? updatedItems : [];
    setSelectItem(selected);
    setVendor({
      value: selectVendor.value,
      error: false,
      success: false,
    });
  };

  let selectItems = selectItem.length > 0 ? false : true;
  const itemReport = () => {
    GetItemReport(budget_id)
      .then((res) => {
        const data = res.data.data;
        GetAllActiveStage()
          .then((res) => {
            const scheduleData = data;
            const stageData = res.data.data;
            let filterData = [];
            scheduleData.map((ele) => {
              return (
                ele,
                stageData.find((elem) => {
                  if (elem.id === JSON.parse(Object.keys(ele))) {
                    let keyName = elem.name;
                    let valueName = ele[Object.keys(ele)];
                    filterData.push({ [keyName]: valueName });
                  }
                })
              );
            });
            const newScheduleReport = Object.assign({}, ...filterData);
            let valuesArray = Object.values(newScheduleReport);
            const initialValue = 0;
            const scheduleItems = valuesArray.reduce(
              (previousValue, currentValue) => previousValue + currentValue,
              initialValue
            );
            newScheduleReport.totalItem = scheduleItems;
            setScheduleReport(newScheduleReport);
          })
          .catch(() => {});
      })
      .catch(() => {});
  };
  React.useEffect(() => {
    itemReport();
  }, []);
  return (
    <div className="modalFormContainer">
      {isLoader ? <LoaderComponent /> : null}
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      {vendorError && <h6 style={{ color: "red" }}>{vendorError}</h6>}
      {isItemForm ? (
        <CreateBudgetItemForm
          handleBudgetItemData={updateBudgetLineItem}
          actionType={"edit"}
          handleModalClose={handleModalClosed}
        />
      ) : (
        <>
          <h2>{createPurchaseOrdersHeader}</h2>
          {screen === 0 && (
            <div>
              <FormDropDown
                labelStyle={{
                  marginTop: "25px",
                  marginBottom: "10px",
                }}
                Label={"Select Vendor"}
                Options={vendorDropDownLineItem}
                value={selectVendor.value}
                onSelect={handleSelectVendor}
                CustomErrorLine={"Select Vendor"}
              />
              <Button
                className="mt-3"
                buttonName={"Submit"}
                buttonStyle={{ backgroundColor: colors.primary }}
                onClick={getItemsByVendor}
              />
            </div>
          )}
          {screen === 1 && (
            <>
              <h3 className="my-3">Budget Item List</h3>
              <div className="col-12">
                <SearchCheckedTables
                  data={rows}
                  mapableData={mapableData}
                  searchProp={["itemCtrlNo"]}
                  handleChangeCheck={handleSelectData}
                  showSelectAll={true} 
                  handleSelectAll={handleSelectAllData}
                />
              </div>
              <div className="row">
                <div
                  style={{
                    display: "flex",
                    marginTop: "1.5rem",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    buttonName={"Cancel"}
                    buttonStyle={{ backgroundColor: colors.primary }}
                    onClick={() => {
                      handleModalClose();
                    }}
                  />
                  <Button
                    buttonName={"Next"}
                    buttonStyle={{ backgroundColor: colors.primary }}
                    onClick={nextBtnClick}
                    disable={selectItems}
                  />
                </div>
              </div>
            </>
          )}
          {screen === 2 && (
            <>
              <div className="row">
                <div className="col-12 col-md-6 pe-0 pe-md-3">
                  <FormDropDown
                    labelStyle={{
                      marginTop: "25px",
                      marginBottom: "10px",
                    }}
                    Label={vendorLabel}
                    Options={vendorDropDown}
                    value={vendor.value}
                    onSelect={handleChangeVendor}
                    CustomErrorLine={vendorErrorLine}
                    selectText={shiptoVendorSelectText}
                    searchable={false}
                    starProps={true}
                    isValidate={true}
                    disable={selectVendor.value ? true : false}
                  />
                </div>
                <div className="col-12 col-md-6 pe-0 pe-md-3">
                  <FormDropDown
                    labelStyle={{
                      marginTop: "20px",
                      marginBottom: "10px",
                    }}
                    Label={shiptoVendorLabel}
                    Options={vendorDropDown}
                    value={shiptoVendor.value}
                    onSelect={handleChangeshiptoVendor}
                    CustomErrorLine={shiptoVendorErrorLine}
                    selectText={shiptoVendorSelectText}
                    searchable={false}
                    starProps={false}
                    isValidate={false}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 pe-0 pe-md-3">
                  <FormTextInput
                    type="textarea"
                    label={billingAddressLabel}
                    placeholder={billingAddressPlaceholder}
                    Value={billingAddress.value}
                    onChangeText={handleChangebillingAddress}
                    Validate={true}
                    CustomErrorLine={billingAddressCustomError}
                    starProps={true}
                    maxLength={200}
                    multiline={true}
                    // editable={billingAddress.value ? false : true}
                    editable={false}
                  />
                </div>
                <div className="col-12 col-md-6 pe-0 pe-md-3">
                  <FormTextInput
                    type="textarea"
                    label={shippingAddressLabel}
                    placeholder={shippingAddressPlaceholder}
                    Value={shippingAddress.value}
                    onChangeText={handleChangeshippingAddress}
                    Validate={true}
                    CustomErrorLine={shippingAddressCustomError}
                    starProps={true}
                    maxLength={200}
                    multiline={true}
                    // editable={shippingAddress.value ? false : true}
                    editable={false}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 pe-0 pe-md-3">
                  <FormDropDown
                    labelStyle={{
                      marginTop: "20px",
                      marginBottom: "10px",
                    }}
                    starProps={true}
                    isValidate={true}
                    Label={deliveryLocationLabel}
                    Options={locationDropDown}
                    value={deliveryLocation.value}
                    onSelect={handleChangedeliveryLocation}
                    CustomErrorLine={deliveryLocationErrorLine}
                    selectText={deliveryLocationSelectText}
                    searchable={false}
                  />
                </div>
                <div className="col-12 col-md-6 pe-0 pe-md-3">
                  <FormDropDown
                    labelStyle={{
                      marginTop: "20px",
                      marginBottom: "10px",
                    }}
                    Label={incotermsLabel}
                    Options={incotermDropDown}
                    value={incoterms.value}
                    onSelect={handleChangeIncoterms}
                    CustomErrorLine={incotermsErrorLine}
                    selectText={incotermsSelectText}
                    searchable={false}
                    starProps={true}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 pe-0 pe-md-3">
                  <FormDropDown
                    labelStyle={{
                      marginTop: "22px",
                      marginBottom: "10px",
                    }}
                    Label={originTypeLabel}
                    Options={countryDropDown}
                    value={originType.value}
                    onSelect={handleChangeOriginType}
                    CustomErrorLine={originTypeErrorLine}
                    selectText={originTypeSelectText}
                    searchable={false}
                    starProps={false}
                    isValidate={false}
                  />
                </div>
                <div className="col-12 col-md-6 pe-0 pe-md-3">
                  <FormDropDown
                    labelStyle={{
                      marginTop: "22px",
                      marginBottom: "10px",
                    }}
                    Label={modeofTransportationLabel}
                    Options={MOTDropDown}
                    value={modeofTransportation.value}
                    onSelect={handleChangemodeofTransportation}
                    CustomErrorLine={modeofTransportationErrorLine}
                    selectText={modeofTransportationSelectText}
                    searchable={false}
                    starProps={false}
                    isValidate={false}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6 pe-0 pe-md-3">
                  <FormTextInput
                    type="textarea"
                    label={originDetailsLabel}
                    placeholder={originDetailsPlaceholder}
                    Value={originDetails.value}
                    onChangeText={handleChangeoriginDetails}
                    Validate={false}
                    CustomErrorLine={originDetailsCustomError}
                    editable={actionType === "add" ? true : false}
                    starProps={false}
                  />
                </div>
              </div>
              <div>
                <div className="row">
                  <h4>Required Documents</h4>
                  {reqrddoc.map((functionName) => {
                    return (
                      <div className={"col-12 col-md-6"}>
                        <FormCheckBox
                          key={functionName.key}
                          Label={functionName.label}
                          checked={functionName.checked}
                          handleChangeCheck={() =>
                            handleChangeCheck(functionName.key)
                          }
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
              <div>
                <div className="row">
                  <h4>Payment Details</h4>
                  <div className="col-12 col-md-6 pe-0 pe-md-2">
                    <FormDropDown
                      labelStyle={{
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                      Label={bankChargesByLabel}
                      Options={bankChargesByOption}
                      value={bankChargesBy.value}
                      onSelect={handleChangebankChargesBy}
                      CustomErrorLine={bankChargesByErrorLine}
                      selectText={bankChargesBySelectText}
                      searchable={false}
                      starProps={true}
                      isValidate={true}
                    />
                  </div>
                  <div className="col-12 col-md-6 pe-0 pe-md-3">
                    <FormDropDown
                      labelStyle={{
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                      Label={modeOfPaymentLabel}
                      Options={ModeofpaymentDropDown}
                      value={modeOfPayment.value}
                      onSelect={handleChangemodeOfPayment}
                      CustomErrorLine={modeOfPaymentErrorLine}
                      selectText={modeOfPaymentSelectText}
                      searchable={false}
                      starProps={true}
                      isValidate={true}
                    />
                  </div>
                </div>

                <div className="row">
                  <h4>{"Payment Terms"}</h4>
                  {paymentTerms.map((functionName) => {
                    return (
                      <div className={"col-12 col-md-6"}>
                        <FormCheckBox
                          key={functionName.key}
                          Label={functionName.label}
                          checked={functionName.checked}
                          handleChangeCheck={(val) => {
                            handleCheckBoxChange();
                            return handleChangePaymentTerms(functionName.key);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
                <div>
                  {showAdvance && (
                    <div style={{ marginTop: "10px" }}>
                      <h4>{advancePaymentHeader}</h4>
                      <div className="row">
                        <div className="row">
                          <div className="col-12 col-md-6 mt-3 pe-0 pe-md-6">
                            <FormTextInput
                              labelTextStyle={{
                                marginTop: "0px",
                                marginBottom: "10px",
                              }}
                              type="text"
                              label={paymentValueLabel}
                              placeholder={advPaymentPlaceholder}
                              Value={advPayment.value}
                              onChangeText={handleChangeAdvPayment}
                              Validate={false}
                              CustomErrorLine={advPaymentCustomError}
                              starProps={false}
                              editable
                            />
                          </div>
                          {/* <div className="col-12 col-md-6 mt-3 pe-0 pe-md-6">
                            <FormTextInput
                              labelTextStyle={{
                                marginTop: "0px",
                                marginBottom: "10px",
                              }}
                              type="number"
                              label={"Percentage"}
                              placeholder={"Enter Advance Percentage"}
                              Value={advPayPercentage.value}
                              onChangeText={handleChangeAdvPayPercentage}
                              Validate={advPayPercentage.value ? true : false}
                              CustomErrorLine={"Enter percentage in number"}
                              starProps={false}
                              editable
                            />
                          </div> */}
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-6 mt-3 pe-0 pe-md-6">
                            <FormDropDown
                              labelStyle={{
                                marginTop: "0px",
                                marginBottom: "10px",
                              }}
                              Options={paymentDropdown1}
                              value={advancePaymentD1.value}
                              onSelect={handleChangeadvancePaymentD1}
                              CustomErrorLine={advancePaymentD1ErrorLine}
                              selectText={advancePaymentD1SelectText}
                              searchable={false}
                              starProps={true}
                              isValidate={true}
                              Validate={true}
                            />
                          </div>
                          <div className="col-12 col-md-6 pe-0 pe-md-6">
                            <FormDropDown
                              labelStyle={{
                                paddingTop: "15px",
                              }}
                              Options={paymentDropdown2}
                              value={advancePaymentD2.value}
                              onSelect={handleChangeadvancePaymentD2}
                              CustomErrorLine={advancePaymentD2ErrorLine}
                              selectText={advancePaymentD2SelectText}
                              searchable={false}
                              starProps={true}
                              isValidate={true}
                              Validate={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {showBeforeShipment && (
                    <div style={{ marginTop: "10px" }}>
                      <h4>{beforeShipmentPaymentHeader}</h4>
                      <div className="row">
                        <div className="col-12 col-md-6 pe-0 pe-md-2">
                          <FormTextInput
                            labelTextStyle={{
                              marginTop: "0px",
                              marginBottom: "10px",
                            }}
                            starProps={true}
                            type="text"
                            label={paymentValueLabel}
                            placeholder={beforeShipmentPaymentPlaceholder}
                            Value={beforeShipmentPayment.value}
                            onChangeText={handleChangeBeforeShipmentPayment}
                            Validate={true}
                            CustomErrorLine={beforeShipmentPaymentCustomError}
                            editable
                          />
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-6 mt-3 pe-0 pe-md-6">
                            <FormDropDown
                              labelStyle={{
                                marginTop: "0px",
                                marginBottom: "10px",
                              }}
                              Options={paymentDropdown1}
                              value={beforeShipmentPaymentD1.value}
                              onSelect={handleChangebeforeShipmentPaymentD1}
                              CustomErrorLine={beforeShipmentPaymentD1ErrorLine}
                              selectText={beforeShipmentPaymentD1SelectText}
                              searchable={false}
                              starProps={true}
                              isValidate={true}
                            />
                          </div>
                          <div className="col-12 col-md-6 pe-0 pe-md-4">
                            <FormDropDown
                              labelStyle={{
                                paddingTop: "15px",
                              }}
                              Options={paymentDropdown2}
                              value={beforeShipmentPaymentD2.value}
                              onSelect={handleChangebeforeShipmentPaymentD2}
                              CustomErrorLine={beforeShipmentPaymentD2ErrorLine}
                              selectText={beforeShipmentPaymentD2SelectText}
                              searchable={false}
                              starProps={true}
                              isValidate={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {showProgress && (
                    <div style={{ marginTop: "10px" }}>
                      <h4>{progressPaymentHeader}</h4>
                      <div className="row">
                        <div className="col-12 col-md-6 pe-0 pe-md-2">
                          <FormTextInput
                            labelTextStyle={{
                              marginTop: "0px",
                              marginBottom: "10px",
                            }}
                            type="text"
                            label={paymentValueLabel}
                            placeholder={progressPaymentPlaceholder}
                            Value={progressPayment.value}
                            onChangeText={handleChangeprogressPayment}
                            Validate={false}
                            CustomErrorLine={progressPaymentCustomError}
                            // starProps={true}
                            editable
                          />
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-6 mt-3 pe-0 pe-md-6">
                            <FormDropDown
                              labelStyle={{
                                marginTop: "0px",
                                marginBottom: "10px",
                              }}
                              Options={paymentDropdown1}
                              value={progressPaymentD1.value}
                              onSelect={handleChangeprogressPaymentD1}
                              CustomErrorLine={progressPaymentD1ErrorLine}
                              selectText={progressPaymentD1SelectText}
                              searchable={false}
                              starProps={true}
                              isValidate={true}
                              Validate={true}
                            />
                          </div>
                          <div className="col-12 col-md-6 pe-0 pe-md-4">
                            <FormDropDown
                              labelStyle={{
                                paddingTop: "15px",
                              }}
                              Options={paymentProgressOption}
                              value={progressPaymentD2.value}
                              onSelect={handleChangeprogressPaymentD2}
                              CustomErrorLine={progressPaymentD2ErrorLine}
                              selectText={progressPaymentD2SelectText}
                              searchable={false}
                              starProps={true}
                              isValidate={true}
                              Validate={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {showFinal && (
                    <div style={{ marginTop: "10px" }}>
                      <h4>{finalPaymentHeader}</h4>
                      <div className="row">
                        <div className="row">
                          <div className="col-12 col-md-6 mt-3 pe-0 pe-md-6">
                            <FormTextInput
                              labelTextStyle={{
                                marginTop: "0px",
                                marginBottom: "10px",
                              }}
                              type="text"
                              label={paymentValueLabel}
                              placeholder={finalPaymentPlaceholder}
                              Value={finalPayment.value}
                              onChangeText={handleChangefinalPayment}
                              Validate={false}
                              CustomErrorLine={finalPaymentCustomError}
                              starProps={false}
                              editable
                            />
                          </div>
                          {/* <div className="col-12 col-md-6 mt-3 pe-0 pe-md-6">
                            <FormTextInput
                              labelTextStyle={{
                                marginTop: "0px",
                                marginBottom: "10px",
                              }}
                              type="number"
                              label={"Percentage"}
                              placeholder={"Enter Final Percentage"}
                              Value={finalPayPercentage.value?.toString()}
                              onChangeText={handleChangeFinalPayPercentage}
                              Validate={false}
                              CustomErrorLine={"Enter percentage in number"}
                              starProps={false}
                              editable
                            />
                          </div> */}
                        </div>

                        <div className="row">
                          <div className="col-12 col-md-6 mt-3 pe-0 pe-md-6">
                            <FormDropDown
                              labelStyle={{
                                marginTop: "0px",
                                marginBottom: "10px",
                              }}
                              Options={paymentDropdown1}
                              value={finalPaymentD1.value}
                              onSelect={handleChangefinalPaymentD1}
                              CustomErrorLine={finalPaymentD1ErrorLine}
                              selectText={finalPaymentD1SelectText}
                              searchable={false}
                              starProps={true}
                              isValidate={true}
                              Validate={true}
                            />
                          </div>
                          <div className="col-12 col-md-6 pe-0 pe-md-6">
                            <FormDropDown
                              labelStyle={{
                                paddingTop: "15px",
                              }}
                              Options={paymentFinalOption}
                              value={finalPaymentD2.value}
                              onSelect={handleChangefinalPaymentD2}
                              CustomErrorLine={finalPaymentD2ErrorLine}
                              selectText={finalPaymentD2SelectText}
                              searchable={false}
                              starProps={true}
                              isValidate={true}
                              Validate={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div>
                <div className="row">
                  <h4
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    Lead Time
                  </h4>
                  <div className="col-12 col-md-6 pe-0 pe-md-3">
                    <FormTextInput
                      type="number"
                      label={shopDrawingLabel}
                      placeholder={shopDrawingPlaceholder}
                      Value={shopDrawing.value}
                      onChangeText={handleChangeshopDrawing}
                      Validate={shopDrawing.value && true}
                      CustomErrorLine={shopDrawingCustomError}
                      starProps={false}
                      editable
                    />
                    <FormTextInput
                      type="number"
                      label={finishSampleLabel}
                      placeholder={finishSamplePlaceholder}
                      Value={finishSample.value}
                      onChangeText={handleChangefinishSample}
                      Validate={finishSample.value && true}
                      CustomErrorLine={finishSampleCustomError}
                      starProps={false}
                      editable
                    />
                    <FormTextInput
                      type="number"
                      label={sampleLabel}
                      placeholder={samplePlaceholder}
                      Value={sample.value}
                      onChangeText={handleChangeSample}
                      Validate={sample.value && true}
                      CustomErrorLine={sampleCustomError}
                      starProps={false}
                      editable
                    />
                  </div>
                  <div className="col-12 col-md-6 pe-0 pe-md-3">
                    <FormTextInput
                      type="number"
                      label={washTestSampleLabel}
                      placeholder={washTestSamplePlaceholder}
                      Value={washTestSample.value}
                      onChangeText={handleChangeWashTestSample}
                      Validate={washTestSample.value && true}
                      CustomErrorLine={washTestSampleCustomError}
                      starProps={false}
                      editable
                    />
                    <FormTextInput
                      type="number"
                      label={productionLabel}
                      placeholder={productionPlaceholder}
                      Value={production.value}
                      onChangeText={handleChangeProduction}
                      Validate={true}
                      CustomErrorLine={productionCustomError}
                      starProps={true}
                      editable
                    />
                    <FormTextInput
                      type="number"
                      label={transitLabel}
                      placeholder={transitPlaceholder}
                      Value={transit.value}
                      onChangeText={handleChangeTransit}
                      Validate={true}
                      CustomErrorLine={transitCustomError}
                      starProps={true}
                      editable
                    />
                  </div>
                </div>
                <div className="row" style={{ marginTop: "10px" }}>
                  <h4>{ProductionTriggersHeader}</h4>
                  {productionTriggers.map((functionName) => {
                    return (
                      <div className={"col-12 col-md-6"}>
                        <FormCheckBox
                          key={functionName.key}
                          Label={functionName.functionName}
                          checked={functionName.checked}
                          handleChangeCheck={() =>
                            handleChangeProductionTriggers(functionName.key)
                          }
                          starProps={false}
                        />
                      </div>
                    );
                  })}
                </div>
                <div className="row" style={{ marginTop: "10px" }}>
                  <h4>{TransitTriggersHeader}</h4>

                  {transitTriggers.map((functionName) => {
                    return (
                      <div className={"col-12 col-md-6"}>
                        <FormCheckBox
                          key={functionName.key}
                          Label={functionName.functionName}
                          checked={functionName.checked}
                          handleChangeCheck={() =>
                            handleChangeTransitTriggers(functionName.key)
                          }
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
              <div>
                <div className="row mt-3">
                  <h4>{"Required Submittals"}</h4>
                  {rqrdSubmittalsCheck.map((functionName) => {
                    return (
                      <div className={"col-12 col-md-6"}>
                        <FormCheckBox
                          starProps={true}
                          key={functionName.key}
                          Label={functionName.label}
                          checked={functionName.checked}
                          handleChangeCheck={() =>
                            handleChangeRqrdSubmittalsCheck(functionName.key)
                          }
                        />
                      </div>
                    );
                  })}
                </div>
                <div className="row">
                  <div className="col-6 col-md-6 pe-0 pe-md-3 mt-3">
                    <div style={{ marginTop: "-20px" }}>
                      {!skip && (
                        <FormTextInput
                          starProps={false}
                          type="textarea"
                          label={specialNotesLabel}
                          placeholder={specialNotesPlaceholder}
                          Value={specialNotes.value}
                          onChangeText={handleChangespecialNotes}
                          Validate={specialNotes.value && true}
                          CustomErrorLine={specialNotesCustomError}
                          multiline={true}
                          editable
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  style={{
                    display: "flex",
                    marginTop: "1.5rem",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Button
                      buttonName={actionType === "add" ? "Back" : "Back"}
                      buttonStyle={{
                        backgroundColor: colors.primary,
                      }}
                      onClick={prevBtnClick}
                    />
                    <Button
                      buttonName={cancelBtn}
                      buttonStyle={{
                        backgroundColor: colors.darkgrey,
                        marginLeft: "4px",
                      }}
                      onClick={() => {
                        handleModalClose();
                      }}
                    />
                  </div>
                  <div>
                    <Button
                      buttonName={actionType === "add" ? "Submit" : "Update"}
                      buttonStyle={{
                        backgroundColor: colors.primary,
                        marginLeft: "10px",
                      }}
                      disable={actionType === "add" ? disable1 : disable1}
                      onClick={handleSaveData}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};
export default CreateBudgetItemPOForm;
