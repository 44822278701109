import AddIcon from "@mui/icons-material/Add";
import DraftsIcon from "@mui/icons-material/Drafts";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Autocomplete, TablePagination, TextField } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import { Doughnut } from "react-chartjs-2";
import { CSVLink } from "react-csv";
import { Helmet } from "react-helmet";
import { FaChartPie } from "react-icons/fa";
import { MdBackupTable, MdOutlineTableChart } from "react-icons/md";
import { useHistory, useLocation, useParams } from "react-router-dom";
import swal from "sweetalert";
import { GetControlNumber } from "../../../axios";
import {
  CreateBudgetItem,
  CreatePurchaseOrder,
  CreateRFI,
  CreateRFQ,
  DeleteBudgetItem,
  DeleteDraftItem,
  GetAllActiveBudgetItems,
  GetAllActiveStage,
  GetAllBudgetFilteItemById,
  GetAssetByBudgetId,
  GetBudgetByID,
  GetBudgetCategory,
  GetBudgetReport,
  GetBudgetSubGroupByGroup,
  GetDeliveryReport,
  GetItemGroupByScopeId,
  GetItemReport,
  GetManagementReport,
  GetProjectById,
  GetStatementReport,
  GetvarianceReport,
  UpdateBudgetItem,
  UpdateBulkBudgetItem,
  UploadBudgetItem,
  getAllActiveVendor,
  getRfiCode,
  GetAllPOByBudget,
  DeleteBulkBudgetItem,
} from "../../../axios";
import {
  // AddIcon,
  AlertMessage,
  BreadCrumbs,
  BudgetItemTable,
  Button,
  FormDropDown,
  IconButton,
  Modal,
  NodataFound,
  ProcessbarTd,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import ModalPdf from "../../../components/common/ModalPdf";
import { colors } from "../../../configs";
import {
  BudgetItemFileUpload,
  BudgetItemimageUpload,
  CreateBudgetItemForm,
  CreateBudgetItemPOForm,
  RequestForQuotationForm,
  RequestforInformationPageForm,
  UploadFile,
} from "../../../forms";
import strings from "../../../translations";
import { isAuthorizedFunc } from "../../../utils";
import {
  CHANGE_BUDGET_ITEM_STATUS_FUNCTION,
  CREATE_BUDGET_ITEM,
  CREATE_PURCHASE_ORDER,
  CREATE_RFI,
  CREATE_RFQ,
  UPDATE_BUDGET_ITEM,
} from "../../../utils/constants";
import { isDecoded, isEncoded } from "../../../utils/crypto";
import AccountStatement from "./AccountStatement";
import "./BudgetItem.css";
import BudgetReport from "./BudgetReport";
import DeliveryDwnld from "./DeliveryDwnld";
import DynamicReport from "./DynamicReport";
import PoSummery from "./PoSummery";
import PrsDownload from "./PrsDownload";
import VarianceReport from "./VarianceReport";
import VendorAcStatement from "./VendorAcStatement";
import VendorReport from "./VendorReport";
import { getItemFromStorage, setItemToStorage } from "../../../utils/storage";
import { BlobProvider } from "@react-pdf/renderer";
import { MoreVert } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";

let rficode = "RFI";

const SearchBudgetItem = (props) => {
  const { initialValue = {} } = props;
  const fetchId = useParams();
  const budId = isDecoded(fetchId.id);
  let budget_id = parseInt(budId);
  const location = useLocation();
  const history = useHistory();

  let budget_scope = location.state?.budgetScope
    ? location.state?.budgetScope.id
    : location?.query
    ? JSON.parse(location.query.params).scope_id
    : null;
  let budget_Name = location.state?.budget_name
    ? location.state?.budget_name
    : location?.query
    ? JSON.parse(location.query.params).budget_name
    : null;
  let proId = location?.state
    ? location?.state.proId
    : location.query
    ? JSON.parse(location.query.params).proId
    : null;

  // const budget_scope = history.location?.state?.budgetScope
  //   ? history.location.state?.budgetScope.id
  //   : history.location?.query ? JSON.parse(location.query.params).budgetScope: null;
  // const budget_Name = history.location?.state?.budget_name
  //   ? history.location?.state?.budget_name
  //   : history.location?.query? JSON.parse(location.query.params).budget_name :null;
  // const proId = history.location?.state
  //   ? history.location?.state.proId
  //   : location.query
  //   ? JSON.parse(location.query.params).proId
  //   : null;

  // console.log("LOCATION",location)
  // console.log("HISTORY",history)
  // console.log("UUUUUUUUUUUUU",budget_scope,budget_Name,proId,budget_id)
  // const handleBeforeUnload = (event) => {

  //   // Perform actions here, e.g., save data or prompt for confirmation
  //   event.preventDefault();
  //   const paramData = JSON.parse(getItemFromStorage("PARAMS"))
  //   console.log("DATA",paramData)
  //   console.log("&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&")
  //   alert("YYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYy")
  //   event.returnValue = 'Test'; // Customizable message for confirmation dialog
  // };

  // useEffect(()=>{
  //   console.log("&&&&&****((")
  //   window.addEventListener('beforeunload', handleBeforeUnload);
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // },[window])

  const params = {
    budget_scope: budget_scope,
    budget_Name: budget_Name,
    proId: proId,
    budget_id: budget_id,
  };
  if (
    budget_scope != null &&
    budget_Name != null &&
    proId != null &&
    budget_id != null
  ) {
    setItemToStorage("PARAMS", params);
  } else {
    const paramData = JSON.parse(getItemFromStorage("PARAMS"));
    budget_id = paramData.budget_id;
    proId = paramData.proId;
    budget_Name = paramData.budget_Name;
    budget_scope = paramData.budget_scope;
  }

  const [newDocument, setNewDocument] = React.useState({
    value: initialValue.newDocument ? initialValue.newDocument : "",
    error: false,
    success: false,
  });
  const [assetArray, setAssetArray] = React.useState([]);
  const [draftStatus, setDraftStatus] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [clickedBtn, setClickedBtn] = React.useState("");
  const [currentUserType, setCurrentUserType] = React.useState({});
  const [alertBox, setAlertBox] = React.useState(false);
  const [draftId, setDraftId] = React.useState(null);
  const [draftAlertBox, setDraftAlertBox] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState();
  const [rseArray, setRseArray] = React.useState([]);
  const [draftItem, setDraftItem] = React.useState([]);
  const [userId, setUserId] = React.useState();
  const [isLoader, setisLoader] = React.useState(false);
  const [isLoaderr, setisLoaderr] = React.useState(false);
  const [errorMessage, setMessage] = React.useState(null);
  const [reportData, setReportData] = React.useState([]);
  const [scheduleReport, setScheduleReport] = React.useState([]);
  const [isAdd, setAdd] = React.useState(true);
  const [isUpdate, setIsUpdate] = React.useState(true);
  const [isStatus, setIsStatus] = React.useState(true);
  const [rfiCodes, setRfiCode] = React.useState(true);
  const [isAddRfi, setIsAddRfi] = React.useState(true);
  const [isAddRfq, setAddRfq] = React.useState(true);
  const [leftDays, setLeftDays] = React.useState(0);
  const [downldName, setDownldName] = React.useState("");
  const [dropDownList, setDropdownList] = React.useState(false);
  const [varData, setVarData] = React.useState([]);
  const [deliverData, setDeliverData] = React.useState([]);
  const [vendorStatement, setVendorStatement] = React.useState([]);
  const [itemGroupList, setItemGroupList] = React.useState([]);
  const [selectGroup, setSelectGroup] = React.useState(null);
  const [itemCategoryList, setItemCategoryList] = React.useState([]);
  const [itemSubGroupList, setSubGroupList] = React.useState([]);
  const [selectCategory, setSelectCategory] = React.useState(null);
  const [selectSubGroup, setSelectSubGroup] = React.useState(null);
  const [searchValue, setSearchValue] = React.useState(null);
  const [vendorDropDown, SetvendorDropDown] = React.useState([]);
  const [searchValues, setSearchValues] = React.useState({
    value: "",
    error: false,
    success: false,
  });
  const [isDropOpen, setIsDropOpen] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [bulkSelectedIds, setBulkSelectedIds] = useState([]);

  // const [vendor, setVendor] = React.useState({
  //   value: initialValue
  //     ? initialValue.budget_vendordetails?.vendordata?.id
  //     : "",
  //   error: false,
  //   success: false,
  // });
  const [vendor, setVendor] = React.useState(null);
  const [projectDetails, setProjectDetails] = React.useState({});
  const [budgetDetail, setBudgetDetail] = React.useState({});
  const [totalData, setTotalData] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isActiveFilter, setIsActiveFilter] = useState(false);
  const [enableAction, setEnableAction] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const handleSearchCtrl = (event) => {
    setSearchValue(event.target.value);
  };

  React.useEffect(() => {
    if (isAuthorizedFunc(CREATE_BUDGET_ITEM)) {
      setAdd(true);
    } else {
      setAdd(false);
    }
  }, []);
  React.useEffect(() => {
    if (isAuthorizedFunc(UPDATE_BUDGET_ITEM)) {
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }
  }, []);
  React.useEffect(() => {
    if (isAuthorizedFunc(CHANGE_BUDGET_ITEM_STATUS_FUNCTION)) {
      setIsStatus(true);
    } else {
      setIsStatus(false);
    }
  }, []);
  React.useEffect(() => {
    if (isAuthorizedFunc(CREATE_RFI)) {
      setIsAddRfi(true);
    } else {
      setIsAddRfi(false);
    }
  }, []);
  React.useEffect(() => {
    if (isAuthorizedFunc(CREATE_RFQ)) {
      setAddRfq(true);
    } else {
      setAddRfq(false);
    }
  }, []);

  const handleChangeNewDocument = (value) => {
    setNewDocument(value);
  };

  const openAddModal = () => {
    setIsOpen(true);
  };

  const handleModalClose = () => {
    setMessage(null);
    setIsOpen(false);
    // childRef.current.resetForm();
  };

  const getDraftItems = () => {
    GetAllActiveBudgetItems(budget_id)
      .then((res) => {
        setDraftItem(res.data.data);
      })
      .catch((e) => {});
  };

  const getProjectDetails = () => {
    GetProjectById(proId)
      .then((res) => {
        setProjectDetails(res.data.data);
      })
      .catch(() => {});
  };

  const getBudgetDetails = () => {
    GetBudgetByID(budget_id)
      .then((res) => {
        setBudgetDetail(res.data.data);
      })
      .catch(() => {});
  };

  React.useEffect(() => {
    getProjectDetails();
    getBudgetDetails();
  }, []);

  React.useEffect(() => {
    if (selectGroup != null) {
      getItemSubGroup();
    }
  }, [selectGroup]);

  const getBudgetScopeGroup = () => {
    GetItemGroupByScopeId(budget_scope)
      .then((res) => {
        let data = res.data.data.item_group;
        const result = data.map((ele) => ({
          key: ele.id,
          value: ele.id,
          label: ele.group_name,
        }));
        setisLoader(false);
        setItemGroupList(result);
      })
      .catch(() => {
        setisLoader(false);
      });
  };
  const getBudgetScopeCategory = () => {
    GetBudgetCategory(budget_id)
      .then((res) => {
        let data = res.data.data;
        const result = data.map((ele) => ({
          key: ele.categoryid,
          value: ele.categoryid,
          label: ele.categoryname,
        }));
        setisLoader(false);
        setItemCategoryList(result);
      })
      .catch(() => {
        setisLoader(false);
      });
  };
  const getItemSubGroup = () => {
    setisLoader(true);
    GetBudgetSubGroupByGroup(budget_id, selectGroup.value)
      .then((res) => {
        let data = res.data.data;
        const result = data.map((ele) => ({
          key: ele.subgroupid,
          value: ele.subgroupid,
          label: ele.subgroupname,
        }));
        console.log("resssss", result);
        setisLoader(false);
        setSubGroupList(result);
      })
      .catch(() => {
        setisLoader(false);
      });
  };

  const GetAllActiveVendor = () => {
    getAllActiveVendor()
      .then((res) => {
        let data = res.data.data;
        const result = data.map((ele) => ({
          key: ele.id,
          value: ele.id,
          label: ele.name,
        }));
        setisLoader(false);
        SetvendorDropDown(result);
      })
      .catch((e) => {
        setisLoader(false);
      });
  };

  React.useEffect(() => {
    getBudgetScopeGroup();
    getBudgetScopeCategory();
    GetAllActiveVendor();
    // getItemSubGroup();
  }, []);

  React.useEffect(() => {
    getDraftItems();
  }, [draftStatus]);

  const [prsModal, setPrsModal] = React.useState(false);

  const handleDwnldPoSummeryClose = () => {
    setPrsModal(false);
  };

  const handleUploadItem = (id) => {
    openAddModal();
    handleSelectBtn("Upload");
  };
  const handlecsvUploadItem = (id) => {
    openAddModal();
    handleSelectBtn("New CSV File");
  };

  const handleSelectBtn = (btn) => {
    setClickedBtn(btn);
  };

  React.useEffect(() => {
    getRfiCode(rficode)
      .then((res) => {
        setRfiCode(res.data.data);
      })
      .catch(() => {});
  }, []);

  const createBudgetLineItem = (formData) => {
    setisLoader(true);
    CreateBudgetItem(formData)
      .then((res) => {
        if (res.status === 200) {
          getAlldata();
          setisLoader(false);
          swal({
            title: "Success!",
            text: res.data.message,
            icon: "success",
          });
          handleModalClose();
        }
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response.data.message);
      });
  };
  const updateBudgetItem = (formData) => {
    setisLoader(true);
    UpdateBudgetItem(formData, userId)
      .then((res) => {
        getAlldata();
        setisLoader(false);
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
        handleModalClose();
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response.data.message);
      });
  };

  const updateBulkItem = (formData) => {
    setisLoader(true);
    UpdateBulkBudgetItem(formData)
      .then((res) => {
        getAlldata();
        setisLoader(false);
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
        handleModalClose();
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response.data.message);
      });
  };

  const getAlldata = (
    selectGroup,
    selectCategory,
    selectSubGroup,
    searchValue,
    vendorValue
  ) => {
    setisLoaderr(true);
    let group_id = selectGroup !== "" ? selectGroup?.value : null;
    let category_id = selectCategory !== "" ? selectCategory?.value : null;
    let subGroup_id = selectSubGroup !== "" ? selectSubGroup?.value : null;
    let search_value = searchValue !== "" ? searchValue?.value : null;
    let vendor_id = vendorValue !== "" ? vendorValue?.value : null;

    let group = group_id === undefined ? null : group_id;
    let category = category_id === undefined ? null : category_id;
    let subGroup = subGroup_id === undefined ? null : subGroup_id;
    let control_no = search_value === undefined ? null : search_value;
    let vendor = vendor_id === undefined ? null : vendor_id;

    GetAllBudgetFilteItemById(
      group,
      category,
      subGroup,
      control_no,
      vendor,
      budget_id,
      page + 1,
      rowsPerPage,
      searchValues.value
    )
      .then((res) => {
        setTotalData(res.data.total);

        setRseArray(res.data.data);
        setisLoaderr(false);
      })
      .catch(() => {
        setisLoaderr(false);
      });
  };

  const handleFilter = () => {
    getAlldata(
      selectGroup,
      selectCategory,
      selectSubGroup,
      searchValue,
      vendor
    );
  };

  const handleCancelSearch = () => {
    // setSearchValue("");
    // setSelectCategory("");
    // setSelectGroup("");
    // setVendor("");
    // handleFilter();
    let group = null;
    let category = null;
    let subGroup = null;
    let searchTextValue = null;
    let vendor = null;
    getAlldata(group, category, subGroup, searchTextValue, vendor);
  };

  const getAssetData = () => {
    setisLoader(true);
    GetAssetByBudgetId(budget_id)
      .then((res) => {
        const result = res.data.data[0].purchase_order_budget_item_details.map(
          (elm) => ({
            itemsubgroup: elm.itemsubgroup,
            purchase_order_name: elm.purchase_order_name,
            serial_number: elm.control_number,
            itemcategory: elm.itemcategory,
            description: elm.description,
            budQty: elm.budget_quantity,
            budUom: elm.budget_umo_Name,
            budCurrency: elm.budget_currency_Name,
            actQty: elm.actual_quantity,
            actUom: elm.actual_uom_Name,
            actCurrency: elm.actual_currency_Name,
            budget_price: (elm.budget_price / elm.budget_quantity).toFixed(2),
            actual_price: (elm.actual_price / elm.budget_quantity).toFixed(2),
            actual_currency_Rate:
              elm.budget_currency_Name === "AED"
                ? elm.actual_currency_Rate
                : (
                    elm.budget_currency_Rev_Rate * elm.actual_currency_Rate
                  ).toFixed(2),
            actual_total: elm.total_price,
            budget_total: (
              (elm.budget_price / elm.budget_quantity) *
              elm.budget_quantity
            ).toFixed(2),
            actual_converted_unit_price: (
              (elm.actual_price / elm.budget_quantity).toFixed(2) *
              (elm.budget_currency_Name === "AED"
                ? elm.actual_currency_Rate
                : elm.budget_currency_Rev_Rate * elm.actual_currency_Rate)
            ).toFixed(2),
            actual_converted_total:
              (
                (elm.actual_price / elm.budget_quantity).toFixed(2) *
                (elm.budget_currency_Name === "AED"
                  ? elm.actual_currency_Rate
                  : elm.budget_currency_Rev_Rate * elm.actual_currency_Rate)
              ).toFixed(2) * elm.budget_quantity,
          })
        );
        setisLoader(false);
        setAssetArray(result);
      })
      .catch((e) => {
        setisLoader(false);
      });
  };

  React.useEffect(() => {
    getAlldata(
      selectGroup,
      selectCategory,
      selectSubGroup,
      searchValue,
      vendor
    );
  }, [page, rowsPerPage]);
  useEffect(() => {
    getAssetData();
  }, []);

  const createRFI = (formData) => {
    setisLoader(true);
    CreateRFI(formData)
      .then((res) => {
        getAlldata();
        setisLoader(false);
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
        handleModalClose();
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response.data.message);
      });
  };

  const draftStatusFunction = (val) => {
    getDraftItems();
  };

  const createRFQ = (formData) => {
    setisLoader(true);
    CreateRFQ(formData)
      .then((res) => {
        getAlldata();
        setisLoader(false);
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
        handleModalClose();
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response.data.message);
      });
  };

  const createBulkBudgetItem = (selectedFile) => {
    setisLoader(true);
    UploadBudgetItem(selectedFile)
      .then((res) => {
        console.log({ res });
        if (res.data.code == 200) {
          getAlldata();
          setisLoader(false);
          swal({
            title: "Success!",
            text: res.data.message,
            icon: "success",
          });
          
          handleModalClose();
        } else if (res.data.code == 409) {
          getAlldata();
          setisLoader(false);
          swal({
            title: "Error!",
            text: res.data.message,
            icon: "error",
          });
          handleModalClose();
        }
      })
      .catch((e) => {
        console.log("catch");
        setisLoader(false);
        setMessage(e.response.data.message);
      });
  };
  const handleCreatePO = (formData) => {
    setisLoader(true);
    CreatePurchaseOrder(formData)
      .then((res) => {
        getAlldata();
        setisLoader(false);
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
        handleModalClose();
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response.data.message);
      });
  };

  const childRef = useRef();
  const selectModal = () => {
    if (clickedBtn === "add") {
      return (
        <CreateBudgetItemForm
          budget_scope={budget_scope}
          budget_id={budget_id}
          setMessage={setMessage}
          errorMessage={errorMessage}
          handleBudgetItemData={
            clickedBtn === "add" ? createBudgetLineItem : ""
          }
          actionType={clickedBtn}
          initialValue={clickedBtn === "add" ? {} : currentUserType}
          handleModalClose={handleModalClose}
          draftStatus={draftStatus}
          draftStatusFunction={draftStatusFunction}
          budgetDetail={budgetDetail}
          ref={childRef}
        />
      );
    } else if (clickedBtn === "New RFI") {
      return (
        <RequestforInformationPageForm
          rfiCodes={rfiCodes}
          budget_id={budget_id}
          setMessage={setMessage}
          errorMessage={errorMessage}
          handleChangeData={createRFI}
          selectedItem={selectedData()}
          actionType={"add"}
          handleModalClose={handleModalClose}
        />
      );
    } else if (clickedBtn === "New RFQ") {
      return (
        <RequestForQuotationForm
          budget_id={budget_id}
          setMessage={setMessage}
          errorMessage={errorMessage}
          handleChangeData={createRFQ}
          selectedItem={selectedData()}
          actionType={"add"}
          handleModalClose={handleModalClose}
        />
      );
    } else if (clickedBtn === "Upload") {
      return (
        <UploadFile
          actionType={"add"}
          handleModalClose={handleModalClose}
          budgetId={budget_id}
        />
      );
    } else if (clickedBtn === "New CSV File") {
      return (
        <BudgetItemFileUpload
          actionType={"add"}
          handleModalClose={handleModalClose}
          handleChangeData={createBulkBudgetItem}
          budget_Name={budget_Name}
          budget_id={budget_id}
        />
      );
    } else if (clickedBtn === "New PO") {
      return (
        <CreateBudgetItemPOForm
          proId={proId}
          budget_id={budget_id}
          selectedItem={selectedData()}
          handleOrdersData={handleCreatePO}
          actionType={"add"}
          handleModalClose={handleModalClose}
          setMessage={setMessage}
          errorMessage={errorMessage}
          projectDetails={projectDetails}
        />
      );
    } else if (clickedBtn === "edit") {
      return (
        <CreateBudgetItemForm
          proId={proId}
          budget_id={budget_id}
          budget_scope={budget_scope}
          handleBudgetItemData={updateBudgetItem}
          actionType={clickedBtn}
          errorMessage={errorMessage}
          initialValue={currentUserType}
          handleModalClose={handleModalClose}
          rseArray={rseArray}
          budgetDetail={budgetDetail}
        />
      );
    } else if (clickedBtn === "Bulk Edit") {
      return (
        <CreateBudgetItemForm
          proId={proId}
          budget_id={budget_id}
          budget_scope={budget_scope}
          handleBudgetItemData={updateBulkItem}
          actionType={"bulkedit"}
          errorMessage={errorMessage}
          initialValue={currentUserType}
          handleModalClose={handleModalClose}
          rseArray={selectedData()}
          budgetDetail={budgetDetail}
        />
      );
    } else if (clickedBtn === "editDraft") {
      return (
        <CreateBudgetItemForm
          budget_scope={budget_scope}
          handleBudgetItemData={createBudgetLineItem}
          actionType={clickedBtn}
          initialValue={currentUserType}
          handleModalClose={handleModalClose}
          setMessage={setMessage}
          errorMessage={errorMessage}
          setDraftStatus={setDraftStatus}
          getDraftItems={getDraftItems}
        />
      );
    } else if (clickedBtn === "Image Upload") {
      return (
        <BudgetItemimageUpload
          actionType={"add"}
          handleModalClose={handleModalClose}
          selectedItem={selectedData()}
        />
      );
    }
  };
  const SearchBudgetItem = strings["SearchBudgetItem.header"],
    furnishTitle = strings["Title.Furnish"],
    BudgetItemTitle = strings["Title.BudgetItems"],
    NewDocumentCustomError = strings["SearchBudgetItem.NewDocumentCustomError"],
    alertMessage = strings["SearchBudgetItem.AlertMessage"],
    alertTitle = strings["SearchBudgetItem.AlertTitle"],
    SerialNo = strings["SearchBudgetItem.tablehead.SerialNo"],
    ItemName = strings["SearchBudgetItem.tablehead.ItemName"],
    Vendors = strings["SearchBudgetItem.tablehead.Vendors"],
    Description = strings["SearchBudgetItem.tablehead.Description"],
    Quantity = strings["SearchBudgetItem.tablehead.Quantity"],
    UOM = strings["SearchBudgetItem.tablehead.UOM"],
    Action = strings["SearchBudgetItem.tablehead.Action"],
    NEWRFI = strings["SearchBudgetItem.dropdown.NEWRFI"],
    NewRFQ = strings["SearchBudgetItem.dropdown.NewRFQ"],
    stageTitle = strings["BudgetSchedule.stageLabel"],
    actQtyLabel = strings["BudgetItem.actualQuantityLabel"],
    budPriceLabel = strings["BudgetItem.budgetPriceLabel"],
    PoLabel = strings["BudgetItem.TablePOLabel"],
    ItemScheduleLabel = strings["BudgetItem.TableItemScheduleLabel"],
    IncotermLoacation = strings["BudgetItem.TableIncotermLabel"],
    DeliveryLabel = strings["BudgetItem.TableDeliveryLocationLabel"],
    CurrentLabel = strings["BudgetItem.TableCurrentLocationLabel"],
    NotesLabel = strings["BudgetItem.TableNotesLabel"],
    ImageUploadLabel = strings["BudgetItem.ImageUpload"],
    OrderItemsValueLabel = strings["BudgetItem.OrderLineItemValueLabel"],
    UnOrderItemsValueLabel = strings["BudgetItem.UnorderLineItemValueLabel"],
    OrderItemsLabel = strings["BudgetItem.OrderLineItemsLabel"],
    UnOrderItemsLabel = strings["BudgetItem.UnorderLineItemsLabel"],
    POLabel = strings["BudgetItem.totalPO"],
    CancelPOLabel = strings["BudgetItem.totalCancelledPO"],
    DeliverPOLabel = strings["BudgetItem.totalDeliveredPO"],
    NotFound = strings["Alert.notFound"];
  const mapableData = [
    {
      dataKey: "control_number",
      title: SerialNo,
      isviewlink: true,
    },
    {
      dataKey: "name",
      title: ItemName,
    },
    {
      dataKey: "stage.name",
      title: "Status",
    },
    {
      dataKey: "budget_vendordetails.vendordata.name",
      title: Vendors,
    },
    {
      dataKey: "item_code",
      title: "Item Code",
    },
    {
      dataKey: "budgetquantitydetails.budget_quantity",
      title: Quantity,
    },
    {
      dataKey: "budgetquantitydetails.actual_quantity",
      title: actQtyLabel,
    },
    {
      dataKey: "budgetquantitydetails.budgetuom.name",
      title: "Budget UOM",
    },
    {
      dataKey: "budgetquantitydetails.actualuom.name",
      title: "Actual UOM",
    },
    {
      dataKey: "budgetquantitydetails.budget_price",
      title: budPriceLabel,
    },
    {
      dataKey: "budgetquantitydetails.actual_price",
      title: "Actual Price",
    },
    {
      dataKey: "id",
      title: Action,
      isAction: true,
      isview: true,
    },
  ];

  const mapableData1 = [
    {
      dataKey: "control_number",
      title: SerialNo,
      isviewlink: true,
    },
    {
      dataKey: "purchaseorderitemdetails.purchaseorder.ship_to_vendor.name",
      title: Vendors,
    },
    {
      dataKey: "description",
      title: Description,
    },
    {
      dataKey: "budgetquantitydetails.budget_quantity",
      title: Quantity,
    },
    {
      dataKey:
        "purchaseorderitemdetails.purchaseorder.package_reference_number",
      title: PoLabel,
    },
    {
      dataKey: `stage.name`,
      title: ItemScheduleLabel,
    },
    {
      dataKey: "purchaseorderitemdetails.purchaseorder.location.name",
      title: IncotermLoacation,
    },
    {
      dataKey: "purchaseorderitemdetails.purchaseorder.incoterm.name",
      title: DeliveryLabel,
    },
    {
      dataKey: "purchaseorderitemdetails.purchaseorder.location.name",
      title: CurrentLabel,
    },
    {
      dataKey: "budget_modeldetails.notes",
      title: NotesLabel,
    },
  ];
  const optionsNewDocument = [
    {
      key: 1,
      label: NEWRFI,
      value: "New RFI",
    },
    {
      key: 2,
      label: NewRFQ,
      value: "New RFQ",
    },
    {
      key: 3,
      label: ImageUploadLabel,
      value: "Image Upload",
    },
    // {
    //   key: 4,
    //   label: "Create PO",
    //   value: "New PO",
    // },
  ];
  const handleChangeEdit = (id) => {
    const result = rseArray.find((res) => {
      return res.id === id;
    });
    setCurrentUserType(result);
    setUserId(result.id);
    openAddModal();
    handleSelectBtn("edit");
  };

  const handleChangeDraftEdit = (id) => {
    if (draftItem.length) {
      const result = draftItem.find((res) => {
        return res.id === id;
      });
      setCurrentUserType(result);
      setUserId(result.id);
      openAddModal();
      handleSelectBtn("editDraft");
    }
  };

  const handleChangeDelete = (id) => {
    setDeleteId(id);
    setAlertBox(!alertBox);
  };

  const handleChangeCancel = () => {
    setAlertBox(false);
  };

  const handleDraftDelete = (id) => {
    setDraftId(id);
    setDraftAlertBox(!draftAlertBox);
  };
  const handleDraftChangeCancel = () => {
    setDraftAlertBox(false);
  };
  const handleChangeDraftSuccess = () => {
    setisLoader(true);
    DeleteDraftItem(draftId)
      .then((res) => {
        getDraftItems();
        setisLoader(false);
      })
      .catch(() => {
        setisLoader(false);
      });
  };
  const handleChangeSuccess = () => {
    setisLoaderr(true);
    DeleteBudgetItem(deleteId)
      .then((res) => {
        const updateData = rseArray.filter((elem) => {
          return elem.id !== res.data.data.id;
        });
        setRseArray(updateData);
        setAlertBox(false);
        setisLoaderr(false);
      })
      .catch((e) => {
        setisLoaderr(false);
      });
  };

  const paths = [
    {
      name: strings["Projects"],
      path: `project`,
    },
    {
      name: strings["Budgets"],
      path: `projectbudget/${fetchId.id}`,
    },
    {
      name: strings["Budget Items"],
      path: "budgetitem",
    },
  ];

  const [checkedItem, setCheckedItem] = React.useState([]);

  const handleChangeSelectedData = (id) => {
    setCheckedItem([...checkedItem, id]);
    const result = rseArray.map((data) => {
      if (data.id === id) {
        return { ...data, checked: !data["checked"] };
      } else {
        return { ...data };
      }
    });
    setRseArray(result);
  };

  const handleChangeButton = () => {
    if (newDocument.value === "Quotation") {
      history.push("/quotation");
    } else if (newDocument.value === "PurchaseOrder") {
      history.push("/purchaseorders");
    } else if (newDocument.value === "Invoice") {
      history.push("/invoice");
    } else if (
      newDocument.value === "New RFI" ||
      newDocument.value === "New RFQ" ||
      newDocument.value === "Image Upload"
    ) {
      openAddModal();
      handleSelectBtn(`${newDocument.value}`);
    }
  };

  const rfiDisableLogic = rseArray.some((data) => {
    return data.checked === true;
  });

  const selectedData = () => {
    const result = rseArray.filter((data) => {
      return data.checked === true;
    });
    console.log({ result });
    return result;
  };

  const handleSelectAll = (checked) => {
    setEnableAction(!enableAction)
    const updatedArray = rseArray.map((item) => ({
      ...item,
      checked, // Set the checked property based on the "select all" state
    }));
    setRseArray(updatedArray);
  };

  const ProcessData = [
    {
      label: OrderItemsLabel,
      percent: reportData.aftercostanalysis
        ? (
            (reportData.aftercostanalysis / reportData.totalItems) *
            100
          ).toFixed(2)
        : 0,
      color: "#da4453",
      data: reportData ? reportData.aftercostanalysis : 0,
    },
    {
      label: UnOrderItemsLabel,
      percent: reportData.beforecostanalysis
        ? (
            (reportData.beforecostanalysis / reportData.totalItems) *
            100
          ).toFixed(2)
        : 0,
      color: "#37bc9b",
      data: reportData ? reportData.beforecostanalysis : 0,
    },
    {
      label: OrderItemsValueLabel,
      percent: reportData.aftercostanalysisprice
        ? (
            (reportData.aftercostanalysisprice / reportData.totalValue) *
            100
          ).toFixed(2)
        : 0,
      color: "green",
      data: reportData ? reportData.aftercostanalysisprice : 0,
    },
    {
      label: UnOrderItemsValueLabel,
      percent: reportData.beforecostanalysisprice
        ? (
            (reportData.beforecostanalysisprice / reportData.totalValue) *
            100
          ).toFixed(2)
        : 0,
      color: "#da4453",
      data: reportData ? reportData.beforecostanalysisprice : 0,
    },
    {
      label: POLabel,
      percent: reportData.totalpuchaseorder
        ? (
            (reportData.totalpuchaseorder / reportData.totalValue) *
            100
          ).toFixed(2)
        : 0,
      color: "black",
      data: reportData ? reportData.totalpuchaseorder : 0,
    },
    {
      label: CancelPOLabel,
      percent: reportData.projectcancelled
        ? (
            (reportData.projectcancelled / reportData.projecttotal) *
            100
          ).toFixed(2)
        : 0,
      color: "yellow",
      data: reportData ? reportData.cancelledpuchaseorder : 0,
    },
    {
      label: DeliverPOLabel,
      percent: reportData.fullydeliveredpuchaseorder
        ? (
            (reportData.fullydeliveredpuchaseorder /
              reportData.totalpuchaseorder) *
            100
          ).toFixed(2)
        : 0,
      color: "green",
      data: reportData ? reportData.fullydeliveredpuchaseorder : 0,
    },
  ];

  const ItemProcessData = [
    {
      label: "Budgetting",
      percent: scheduleReport.Budgetting
        ? (
            (scheduleReport.Budgetting / scheduleReport.totalItem) *
            100
          ).toFixed(2)
        : 0,
      color: "#da4453",
      data: scheduleReport.Budgetting ? scheduleReport.Budgetting : 0,
    },

    {
      label: "Rfq",
      percent: scheduleReport.Rfq
        ? ((scheduleReport.Rfq / scheduleReport.totalItem) * 100).toFixed(2)
        : 0,
      color: "black",
      data: scheduleReport.Rfq ? scheduleReport.Rfq : 0,
    },
    {
      label: "Recommendation",
      percent: scheduleReport.Recommendation
        ? (
            (scheduleReport.Recommendation / scheduleReport.totalItem) *
            100
          ).toFixed(2)
        : 0,
      color: "blue",
      data: scheduleReport.Recommendation ? scheduleReport.Recommendation : 0,
    },
    {
      label: "Ordering",
      percent: scheduleReport.Ordering
        ? ((scheduleReport.Ordering / scheduleReport.totalItem) * 100).toFixed(
            2
          )
        : 0,
      color: "yellow",
      data: scheduleReport.Ordering ? scheduleReport.Ordering : 0,
    },
    {
      label: "Pre-Production",
      color: "yellow",
      percent: scheduleReport.Preproduction
        ? (
            (scheduleReport.Preproduction / scheduleReport.totalItem) *
            100
          ).toFixed(2)
        : 0,
      data: scheduleReport.Preproduction ? scheduleReport.Preproduction : 0,
    },
    {
      label: "Production",
      percent: scheduleReport.Production
        ? (
            (scheduleReport.Production / scheduleReport.totalItem) *
            100
          ).toFixed(2)
        : 0,
      color: "pink",
      data: scheduleReport.Production ? scheduleReport.Production : 0,
    },
    {
      label: "Transit",
      percent: scheduleReport.Transit
        ? ((scheduleReport.Transit / scheduleReport.totalItem) * 100).toFixed(2)
        : 0,
      color: "green",
      data: scheduleReport.Transit ? scheduleReport.Transit : 0,
    },
    {
      label: "Completion",
      percent: scheduleReport.Completion
        ? (
            (scheduleReport.Completion / scheduleReport.totalItem) *
            100
          ).toFixed(2)
        : 0,
      color: "marron",
      data: scheduleReport.Completion ? scheduleReport.Completion : 0,
    },
  ];

  const ItemDoughnutdata = {
    labels: [
      "Budgetting",
      "Rfq",
      "Recommendation",
      "Ordering",
      "Preproduction",
      "Production",
      "Transit",
      "Completion",
    ],
    datasets: [
      {
        label: "Doughnut Reprsesntation",
        data: [
          scheduleReport.Budgetting ? scheduleReport.Budgetting : 0,
          scheduleReport.Rfq ? scheduleReport.Rfq : 0,
          scheduleReport.Recommendation ? scheduleReport.Recommendation : 0,
          scheduleReport.Ordering ? scheduleReport.Ordering : 0,
          scheduleReport.Preproduction ? scheduleReport.Preproduction : 0,
          scheduleReport.Production ? scheduleReport.Production : 0,
          scheduleReport.Transit ? scheduleReport.Transit : 0,
          scheduleReport.Completion ? scheduleReport.Completion : 0,
        ],
        backgroundColor: [
          "#da4453",
          "black",
          "blue",
          "#37bc9b",
          "yellow",
          "pink",
          "green",
          "marron",
        ],
        borderWidth: 2,
      },
    ],
  };

  const Doughnutdata = {
    labels: ["Ordered", "Unordered"],
    datasets: [
      {
        label: "Doughnut Reprsesntation",
        data: [
          reportData ? reportData.aftercostanalysis : 0,
          reportData ? reportData.beforecostanalysis : 0,
        ],
        backgroundColor: ["#da4453", "#37bc9b"],
        borderWidth: 2,
      },
    ],
  };

  const [type, setType] = React.useState("item");

  const tableDropdown1 = () => {
    setType("item");
  };
  const tableDropdown2 = () => {
    setType("status");
  };
  const chartFunction = () => {
    setType("graph");
  };
  const draftTable = () => {
    setType("draft");
  };

  const budgetReport = () => {
    GetBudgetReport(budget_id)
      .then((res) => {
        let totalValue =
          res.data.data.aftercostanalysisprice +
          res.data.data.beforecostanalysisprice;
        let totalItems =
          res.data.data.beforecostanalysis + res.data.data.aftercostanalysis;
        let data = res.data.data;
        Object.assign(
          data,
          { totalItems: totalItems },
          { totalValue: totalValue }
        );
        setReportData(data);
      })
      .catch(() => {});
  };
  const itemReport = () => {
    GetItemReport(budget_id)
      .then((res) => {
        const data = res.data.data;
        GetAllActiveStage()
          .then((res) => {
            console.log("res", res);
            const scheduleData = data;
            const stageData = res.data.data;
            let filterData = [];
            scheduleData.map((ele) => {
              return (
                ele,
                stageData.find((elem) => {
                  if (elem.id === JSON.parse(Object.keys(ele))) {
                    let keyName = elem.name;
                    let valueName = ele[Object.keys(ele)];
                    filterData.push({ [keyName]: valueName });
                  }
                })
              );
            });
            const newScheduleReport = Object.assign({}, ...filterData);
            let valuesArray = Object.values(newScheduleReport);
            const initialValue = 0;
            const scheduleItems = valuesArray.reduce(
              (previousValue, currentValue) => previousValue + currentValue,
              initialValue
            );
            newScheduleReport.totalItem = scheduleItems;
            setScheduleReport(newScheduleReport);
          })
          .catch(() => {});
      })
      .catch(() => {});
  };
  React.useEffect(() => {
    budgetReport();
    itemReport();
  }, []);
  const handleView = (data) => {
    const item_Id = isEncoded(data.id);
    history.push({
      pathname: `/itemschedule/${item_Id}`,
      state: rseArray,
      budget_id: budget_id,
      budget_name: budget_Name,
      proId: proId,
      budget_scope: budget_scope,
    });
  };
  const handleFlag = (id) => {
    const result = rseArray.find((res) => {
      return res.id === id;
    });
    var a = moment(result.rfi?.rfidata.deadline.slice(0, 10));
    var b = moment(result.rfi?.rfidata.created_on.slice(0, 10));
    setLeftDays(a.diff(b, "days"));
  };

  const FullTableheaders = [
    { label: "serial Number", key: "control_number" },
    { label: "Name", key: "name" },
    { label: "Item Code", key: "item_code" },
    { label: "Description", key: "description" },
    { label: "Stage Name", key: "stage.name" },
    {
      label: "Vendor Name",
      key: "purchaseorderitemdetails.purchaseorder.ship_to_vendor.name",
    },
    { label: "SubGroup", key: "itemcategory.name" },
    { label: "Group", key: "itemgroup.group_name" },
    { label: "Sub-Group", key: "itemsubgroup.name" },
    { label: "Brand", key: "brand" },
    { label: "Budget Quantity", key: "budgetquantitydetails.budget_quantity" },
    { label: "Actual Quantity", key: "budgetquantitydetails.actual_quantity" },
    { label: "Budget UOM Name", key: "budgetquantitydetails.budgetuom.name" },
    { label: "Budget Price", key: "budgetquantitydetails.budget_price" },
    {
      label: "Package Reference Number",
      key: "purchaseorderitemdetails.purchaseorder.package_reference_number",
    },
    {
      label: "Delivery Location Name",
      key: "purchaseorderitemdetails.purchaseorder.location.name",
    },
    {
      label: "Incoterm",
      key: "purchaseorderitemdetails.purchaseorder.incoterm.name",
    },
    {
      label: "Current Location Name",
      key: "purchaseorderitemdetails.purchaseorder.origin_details",
    },
    { label: "Notes", key: "budget_modeldetails.notes" },
  ];
  const fullTablecsvReport = {
    data: rseArray,
    headers: FullTableheaders,
    filename: "Full_Table_Report.csv",
  };
  const Budgteheaders = [
    { label: "serial Number", key: "control_number" },
    { label: "Name", key: "name" },

    { label: "Description", key: "description" },
    { label: "Stage Name", key: "stage.name" },
    {
      label: "Vendor Name",
      key: "purchaseorderitemdetails.purchaseorder.ship_to_vendor.name",
    },
    { label: "Budget Quantity", key: "budgetquantitydetails.budget_quantity" },
    { label: "Budget Price", key: "budgetquantitydetails.budget_price" },
    { label: "Budget UOM Name", key: "budgetquantitydetails.budgetuom.name" },
    { label: "Actual Quantity", key: "budgetquantitydetails.actual_quantity" },
    { label: "Actual Price", key: "budgetquantitydetails.actual_price" },
    { label: "Actual UOM Name", key: "budgetquantitydetails.actualuom.name" },
    { label: "Unit Price", key: "purchaseorderitemdetails.unit_price" },
    { label: "Total Price", key: "purchaseorderitemdetails.total_price" },
  ];
  const budgetTablecsvReport = {
    data: rseArray,
    headers: Budgteheaders,
    filename: "Budget_Table_Report.csv",
  };
  const Varianceheaders = [
    { label: "Sub Group", key: "subGroup" },
    { label: "Category", key: "itemCategory" },
    { label: "Control Number", key: "ctrlNo" },
    { label: "Reference", key: "reference" },
    { label: "Vendor", key: "vendorName" },
    { label: "PO number", key: "po_no" },
    { label: "Short Description", key: "desc" },
    { label: "Budget Qty", key: "budQty" },
    { label: "Budget UOM", key: "budUom" },
    { label: "Budget Currency", key: "budCurrency" },
    { label: "Budget Unit Price", key: "budget_price" },
    { label: "Budget Total", key: "budTotalPrice" },
    { label: "Budget Converted Total", key: "budTotalPrice" },
    { label: "Actual Qty", key: "actQty" },
    { label: "Actual UOM", key: "actUom" },
    { label: "Actual Unit Price", key: "actual_price" },

    { label: "Actual Currency", key: "actCurrency" },
    { label: "Exchange Rate to AED", key: "actUnitPrice" },
    { label: "Actual Converted Total", key: "actTotalPrice" },
    { label: "Variance", key: "variance" },
  ];
  const varianceTablecsvReport = {
    data: varData,
    headers: Varianceheaders,
    filename: "Variance_Table_Report.csv",
  };
  const Deliveryheaders = [
    { label: "Group", key: "group" },
    { label: "SubGroup", key: "subGroup" },
    { label: "Category", key: "itemCategory" },
    { label: "Control Number", key: "ctrlNo" },
    { label: "Reference", key: "reference" },
    { label: "Vendor", key: "vendorName" },
    { label: "Ship To Vendor", key: "shipVendor" },
    { label: "PO number", key: "po_no" },
    { label: "Short Description", key: "desc" },
    { label: "Item Description", key: "itemDesc" },
    { label: "Actual Qty", key: "actQty" },
    { label: "Actual UOM", key: "actUom" },
    { label: "Total Delivered", key: "totalDeliver" },
    { label: "Total Remaining", key: "totalRemins" },
    { label: "Delivery Comments", key: "deliveryComment" },
  ];
  const deliveryTablecsvReport = {
    data: deliverData,
    headers: Deliveryheaders,
    filename: "Delivery_Table_Report.csv",
  };
  const AssetRegisterheaders = [
    { label: "subgroup", key: "itemsubgroup" },
    { label: "purchase order name", key: "purchase_order_name" },
    { label: "serial number", key: "serial_number" },
    { label: "Item category", key: "itemcategory" },
    { label: "Description", key: "description" },
    { label: "Budget Qty", key: "budQty" },
    { label: "Budget UOM", key: "budUom" },
    { label: "Budget Currency", key: "budCurrency" },
    { label: "Budget Unit Price", key: "budget_price" },
    { label: "Budget Total", key: "budget_total" },
    { label: "Actual Qty", key: "actQty" },
    { label: "Actual UOM", key: "actUom" },
    { label: "Actual Currency", key: "actCurrency" },
    { label: "Actual Unit Price", key: "actual_price" },
    { label: "Actual Total", key: "actual_total" },
    { label: "Exchange Rate to AED", key: "actual_currency_Rate" },
    {
      label: "Actual Converted Unit Price",
      key: "actual_converted_unit_price",
    },
    { label: "Actual Converted Total", key: "actual_converted_total" },
  ];
  const assetRegisterTablecsvReport = {
    data: assetArray,
    headers: AssetRegisterheaders,
    filename: "AssetRegister_Table_Report.csv",
  };
  const StatementOfAccountheaders = [
    { label: "VENDOR", key: "name" },
    { label: "INV TYPE", key: "inv_type" },
    { label: "INV #", key: "invoice_serial_number" },
    { label: "CURRENCY", key: "actual_currency_Name" },
    { label: "INV RECEIVED DATE", key: "received_on" },
    { label: "DATE OF PAYMENT", key: "due_on" },
    { label: "ACTUAL INV AMOUNT", key: "total_price" },
    { label: "CONVERTED INV AMOUNT", key: "total_price" },
    { label: "PAID AMOUNT", key: "total_amount" },
    { label: "BALANCE TO BE PAID", key: "total_amount" },
  ];
  const statementofAccountTablecsvReport = {
    data: vendorStatement,
    headers: StatementOfAccountheaders,
    filename: "StatementOfAccount_Table_Report.csv",
  };
  const statementOfAccountVendorheaders = [
    { label: "VENDOR", key: "name" },
    { label: "PO NUMBER", key: "purchase_order_serial_number" },
    { label: "INV TYPE", key: "inv_type" },
    { label: "INV #", key: "invoice_serial_number" },
    { label: "CURRENCY", key: "actual_currency_Name" },
    { label: "INV RECEIVED DATE", key: "received_on" },
    { label: "DATE OF PAYMENT", key: "due_on" },
    { label: "ACTUAL INV AMOUNT", key: "total_price" },
    { label: "CONVERTED INV AMOUNT", key: "total_price" },
    { label: "PAID AMOUNT", key: "total_amount" },
    { label: "BALANCE TO BE PAID", key: "total_amount" },
  ];
  const statementOfAccountVendorTablecsvReport = {
    data: vendorStatement,
    headers: statementOfAccountVendorheaders,
    filename: "StatementOfAccountVendor_Table_Report.csv",
  };
  const handleChangeDrop = () => {
    setDropdownList(!dropDownList);
  };
  const selectDownloadModal = () => {
    return <PrsDownload />;
  };
  const [isDownloadOpen, setIsDownloadOpen] = React.useState(false);
  const [controlNumber, setControlNumber] = React.useState(null);

  const getControlNumber = () => {
    GetControlNumber()
      .then((res) => {
        console.log("response ", res);
        setControlNumber(res.data.data);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  useEffect(() => {
    getControlNumber();
  }, []);

  const openDownloadModal = () => {
    setIsDownloadOpen(true);
  };
  const handleDownloadModalClose = () => {
    setIsDownloadOpen(false);
  };
  const handleDwnldDelivery = (e) => {
    setDownldName(e.target.name);
    openDownloadModal();
  };
  const handleManagement = (e) => {
    setDownldName(e.target.name);
    openDownloadModal();
  };
  const handleBudgetReport = (e) => {
    setDownldName(e.target.name);
    openDownloadModal();
  };
  const downloadModal = () => {
    if (downldName === "deliveryReport") {
      return (
        <DeliveryDwnld
          id={budget_id}
          isDeliveryOpened={downldName === "deliveryReport"}
        />
      );
    } else if (downldName === "statementVendorAc") {
      return <VendorAcStatement id={budget_id} />;
    } else if (downldName === "acStatement") {
      return <AccountStatement id={budget_id} />;
    } else if (downldName === "poSummery") {
      return (
        <PoSummery id={budget_id} isPOOpened={downldName === "poSummery"} />
      );
    } else if (downldName === "vendorReport") {
      return (
        <VendorReport
          id={budget_id}
          isVendorOpened={downldName === "vendorReport"}
        />
      );
    } else if (downldName === "dynamicReport") {
      return <DynamicReport id={budget_id} />;
    } else if (downldName === "varianceReport") {
      return (
        <VarianceReport
          id={budget_id}
          isVarianceOpened={downldName === "varianceReport"}
        />
      );
    } else if (downldName === "handleBudgetReport") {
      return (
        <BudgetReport
          id={budget_id}
          isBudgetOpen={downldName === "handleBudgetReport"}
        />
      );
    }
  };
  const getVarianceData = () => {
    GetvarianceReport(budget_id)
      .then((res) => {
        const result = res.data.data[0].purchase_order_budget_item_details.map(
          (ele) => ({
            subGroup: ele.itemsubgroup,
            itemCategory: ele.itemcategory,
            ctrlNo: ele.control_number,
            reference: "N/A",
            vendorName: ele.vendor_name,
            po_no: ele.purchase_order_serial_number,
            desc: ele.name,
            budQty: ele.budget_quantity,
            budUom: ele.budget_umo_Name,
            budCurrency: ele.budget_currency_Name,
            budUnitPrice: ele.budget_price / parseInt(ele.budget_quantity),
            budTotalPrice: ele.budget_price,
            actQty: ele.actual_quantity,
            actUom: ele.actual_uom_Name,
            actCurrency: ele.actual_currency_Name,
            // actUnitPrice: ele.actual_price / parseInt(ele.actual_quantity),
            budget_price: (ele.budget_price / ele.budget_quantity).toFixed(2),
            actual_price: (ele.actual_price / ele.budget_quantity).toFixed(2),
            actUnitPrice:
              ele.budget_currency_Name === "AED"
                ? ele.actual_currency_Rate
                : (
                    ele.budget_currency_Rev_Rate * ele.actual_currency_Rate
                  ).toFixed(2),

            actTotalPrice:
              (
                (ele.actual_price / ele.budget_quantity).toFixed(2) *
                (ele.budget_currency_Name === "AED"
                  ? ele.actual_currency_Rate
                  : ele.budget_currency_Rev_Rate * ele.actual_currency_Rate)
              ).toFixed(2) * ele.budget_quantity,
            variance:
              ele.budget_price -
              (
                (ele.actual_price / ele.budget_quantity).toFixed(2) *
                (ele.budget_currency_Name === "AED"
                  ? ele.actual_currency_Rate
                  : ele.budget_currency_Rev_Rate * ele.actual_currency_Rate)
              ).toFixed(2) *
                ele.budget_quantity,
          })
        );
        setVarData(result);
      })
      .catch(() => {});
  };
  const getDeliveryData = () => {
    GetDeliveryReport(budget_id)
      .then((res) => {
        const result =
          res.data.data.deliveryData[0].purchase_orderdelivery_details.map(
            (ele) => ({
              group: ele.item_group_name ? ele.item_group_name : "N/A",
              subGroup: ele.itemsubgroup,
              itemCategory: ele.itemcategory,
              ctrlNo: ele.control_number,
              reference: "N/A",
              vendorName: ele.vendor_name,
              shipVendor: ele.vendor_ship_name,
              po_no: ele.purchase_order_serial_number,
              desc: ele.name,
              itemDesc: ele.description,
              actQty: ele.actual_quantity,
              actUom: ele.UMO_name,
              totalDeliver: ele.totaldelivered_item,
              totalRemins:
                parseInt(ele.actual_quantity) -
                parseInt(ele.totaldelivered_item),
              deliveryComment: "N/A",
            })
          );
        setDeliverData(result);
      })
      .catch(() => {});
  };
  const getVendorStatement = () => {
    GetStatementReport(budget_id)
      .then((res) => {
        const result = [];
        res.data.data.summaryData.forEach((res) => {
          result.push({ name: res.name });
          const statementData = res.purchase_order_invoice.map((ele) => ({
            purchase_order_serial_number: ele.purchase_order_serial_number,
            inv_type: "",
            invoice_serial_number: ele.invoice_serial_number,
            actual_currency_Name: ele.actual_currency_Name,
            received_on: moment(ele.received_on.split(" ")[0]).format(
              "DD-MM-YYYY"
            ),
            due_on: moment(ele.due_on.split(" ")[0]).format("DD-MM-YYYY"),
            total_price: ele.total_price,
            total_amount: ele.total_amount,
          }));
          result.push(...statementData);
        });
        setVendorStatement(result);
      })
      .catch(() => {});
  };
  const handleDownloadManagement = () => {
    setisLoader(true);
    GetManagementReport(budget_id)
      .then((res) => {
        setisLoader(false);
        window.open(process.env.REACT_APP_BASE_URL2 + "/" + res.data.data);
      })
      .catch(() => {
        setisLoader(false);
      });
  };
  React.useEffect(() => {
    getVarianceData();
    getDeliveryData();
    getVendorStatement();
  }, []);

  const handleCreatePOModal = () => {
    openAddModal();
    setClickedBtn("New PO");
  };
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    getAlldata();
  };

  const menuRef = useRef(null);
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsDropOpen(false);
    }
  };

  useEffect(() => {
    if (isDropOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropOpen]);

  const toggleMenu = () => {
    setIsDropOpen(!isOpen);
  };

  const handleBulkEdit = () => {
    openAddModal();
    handleSelectBtn("Bulk Edit");
    setIsDropOpen(false);
  };

  const getPurchaseOrder = () => {
    setisLoader(true);
    GetAllPOByBudget(budget_id)
      .then((res) => {
        setisLoader(false);
        setData(res.data.data);
        console.log("Purchase Order Data:", res.data.data);
      })
      .catch((error) => {
        setisLoader(false);
      });
  };

  React.useEffect(() => {
    getPurchaseOrder();
  }, []);

  const [cantDelete, setCantDelete] = useState(false);

  const handleBulkDelete = () => {
    const selectedItems = selectedData();
    const selectedIds = selectedItems.map((item) => item.id);

    const budgetItemIdsInPO = data.flatMap((po) =>
      po.item_details.map((detail) => detail.budgetitem?.id).filter(Boolean)
    );

    // setBulkSelectedIds(selectedIds);
    // const itemInPO = selectedIds.some((id) => budgetItemIdsInPO.includes(id));

    // if (itemInPO) {
    //   setModalMessage("Items are associated with a Purchase Order. Are you sure you want to proceed with deletion?");
    // } else {
    //   setModalMessage("Do you want to delete these items?");
    // }

    const restrictedStatus = [
      "Signed By Client",
      "Rejected By Client",
      "Send To Client",
    ];

    const hasRestrictedStatus = selectedItems.some((item) => {
      const status = item?.purchaseorderitemdetails?.purchaseorder?.purchase_order_status;
      return status && restrictedStatus.includes(status);
    });

    console.log({ hasRestrictedStatus });

    if (hasRestrictedStatus) {
      setCantDelete(true);
      setModalMessage(
        "Budget items cannot be deleted as the purchase order is either signed, rejected, or sent to the client."
      );
    } else {
      setCantDelete(false);

      setBulkSelectedIds(selectedIds);
      const itemInPO = selectedIds.some((id) => budgetItemIdsInPO.includes(id));

      if (itemInPO) {
        setModalMessage(
          "Items are associated with a Purchase Order. Are you sure you want to proceed with deletion?"
        );
      } else {
        setModalMessage("Do you want to delete these items?");
      }
    }

    setIsModalOpen(true);
  };

  const handleBulkChangeSuccess = (bulkIDs) => {
    setisLoaderr(true);

    const payload = {
      items: bulkIDs.map((id) => ({ id })),
    };

    console.log("Payload:", payload);

    DeleteBulkBudgetItem(payload)
      .then((res) => {
        const updateData = rseArray.filter(
          (elem) => !bulkIDs.includes(elem.id)
        );
        setRseArray(updateData);
        setIsModalOpen(false);
        setisLoaderr(false);
      })
      .catch((e) => {
        setisLoaderr(false);
      });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="content-wrapper p-0">
      <Helmet>
        <title>
          {furnishTitle} | {BudgetItemTitle}
        </title>
      </Helmet>
      {isLoader ? <LoaderComponent /> : null}
      <ModalPdf isOpen={prsModal} onHandleClose={handleDwnldPoSummeryClose}>
        {selectDownloadModal()}
      </ModalPdf>
      <ModalPdf
        isOpen={isDownloadOpen}
        onHandleClose={handleDownloadModalClose}
      >
        {downloadModal()}
      </ModalPdf>
      <div
        className="row mt-4"
        style={{
          alignItems: "center",
        }}
      >
        <div className="col mb-3">
          <h3>{SearchBudgetItem}</h3>
          <BreadCrumbs pathList={paths} obj={{ proId: proId }} />
        </div>
        <div className="col-auto">
          <span
            className="addButton"
            onClick={() => {
              setIsActiveFilter(!isActiveFilter);
            }}
          >
            <IconButton>
              <Tooltip title="Filter" placement="bottom">
                <FilterAltIcon
                  style={{
                    fontSize: "23px",
                    margin: "4px 5px ",
                    cursor: "pointer",
                    color: colors.primary,
                  }}
                />
              </Tooltip>
            </IconButton>
          </span>
          <span
            className="addButton"
            onClick={() => {
              handlecsvUploadItem("");
            }}
          >
            <IconButton>
              <Tooltip title="Upload from CSV" placement="bottom">
                <FileUploadIcon
                  style={{
                    fontSize: "23px",
                    margin: "4px 5px ",
                    cursor: "pointer",
                    color: colors.primary,
                  }}
                />
              </Tooltip>
            </IconButton>
          </span>
          {isAdd && (
            // <div className="col-auto">
            <span className="addButton">
              <IconButton
                onClick={() => {
                  openAddModal();
                  handleSelectBtn("add");
                }}
              >
                <Tooltip title="Add">
                  <AddIcon
                    style={{
                      fontSize: "23px",
                      margin: "4px 5px ",
                      cursor: "pointer",
                      color: colors.primary,
                    }}
                  />
                </Tooltip>
              </IconButton>
            </span>
            // </div>
          )}
        </div>
      </div>

      <Modal
        onHandleClose={handleModalClose}
        isOpen={isOpen}
        contentStyle={{
          width: "90vw",
          height: "90vh",
        }}
      >
        {selectModal()}
      </Modal>

      {alertBox && (
        <AlertMessage
          title={alertTitle}
          message={alertMessage}
          onChangeCancel={handleChangeCancel}
          onChangeSucess={handleChangeSuccess}
        />
      )}
      {draftAlertBox && (
        <AlertMessage
          title={alertTitle}
          message={alertMessage}
          onChangeCancel={handleDraftChangeCancel}
          onChangeSucess={handleChangeDraftSuccess}
        />
      )}

      <div className="row justify-content-between align-items-center">
        <div className="d-flex col-md-12">
          <div>
            <button
              className={"budgetitembutton me-3"}
              onClick={() => {
                history.push({
                  pathname: `/searchdocumentrfi/${fetchId.id}`,
                  state: {
                    proId: proId,
                    budget_id: budget_id,
                    budget_name: budget_Name,
                    scope_id: budget_scope,
                  },
                });
              }}
            >
              RFI
            </button>
          </div>

          <div>
            <button
              className={"budgetitembutton me-3"}
              onClick={() => {
                localStorage.setItem("directPO", JSON.stringify(false));
                history.push({
                  pathname: `/rfq/${fetchId.id}`,
                  state: {
                    proId: proId,
                    budget_id: budget_id,
                    budget_name: budget_Name,
                    scope_id: budget_scope,
                  },
                });
              }}
            >
              RFQ
            </button>
          </div>

          <div>
            <button
              className={"budgetitembutton me-3"}
              onClick={() => {
                localStorage.setItem("directPO", JSON.stringify(true));
                history.push({
                  pathname: `/purchaseorders/${fetchId.id}`,
                  state: {
                    proId: proId,
                    budget_id: budget_id,
                    budget_name: budget_Name,
                    scope_id: budget_scope,
                  },
                });
              }}
            >
              Purchase Order
            </button>
          </div>

          <div>
            <button
              className={"budgetitembutton me-3"}
              onClick={() => {
                history.push({
                  pathname: `/assetregister/${fetchId.id}`,
                  state: {
                    proId: proId,
                    budget_id: budget_id,
                    budget_name: budget_Name,
                    scope_id: budget_scope,
                  },
                });
              }}
            >
              Asset Register
            </button>
          </div>
          <div>
            <button
              className={"budgetitembutton me-3"}
              onClick={() => {
                history.push({
                  pathname: `/document/${fetchId.id}`,
                  state: {
                    proId: proId,
                    budget_id: budget_id,
                    budget_name: budget_Name,
                    scope_id: budget_scope,
                  },
                });
              }}
            >
              View Document
            </button>
          </div>
          <div>
            {user.loginType != "client" && (
              <button
                className={"budgetitembutton me-3"}
                onClick={() => {
                  handleUploadItem("");
                }}
              >
                Budget Upload
              </button>
            )}
          </div>
          <div>
            <button
              className={"budgetitembutton me-3"}
              onClick={() => {
                history.push({
                  pathname: `/groups/${fetchId.id}`,
                  state: {
                    proId: proId,
                    budget_id: budget_id,
                    budget_name: budget_Name,
                    scope_id: budget_scope,
                  },
                });
              }}
            >
              Groups
            </button>
          </div>
          <div>
            <button
              className={"budgetitembutton me-3"}
              onClick={() => {
                history.push({
                  pathname: `/categories/${fetchId.id}`,
                  state: {
                    proId: proId,
                    budget_id: budget_id,
                    budget_name: budget_Name,
                    scope_id: budget_scope,
                  },
                });
              }}
            >
              Categories
            </button>
          </div>
          {/* <div>
            <button
              className={"budgetitembutton me-3"}
              onClick={() => {
                history.push({
                  pathname: `/paymentsummary/${fetchId.id}`,
                  state: {
                    proId: proId,
                    budget_id: budget_id,
                    budget_name: budget_Name,
                    scope_id: budget_scope
                  },
                });
              }}
            >
              Payment Summary
            </button>
          </div> */}
        </div>
      </div>
      {isActiveFilter ? (
        <>
          <div className="d-flex mt-3">
            <div
              style={{
                position: "relative",
                zIndex: "9",
                width: "200px",
                marginRight: "15px",
              }}
            >
              {/* <select
            className="form-select"
            aria-label="Default select example"
            onChange={(e) => setSelectGroup(e.target.value)}
            value={selectGroup}
          >
            <option selected>Select group</option>
            {itemGroupList &&
              itemGroupList.map((ele) => {
                return (
                  <option value={ele.value} key={ele.value}>
                    {ele.label}
                  </option>
                );
              })}
          </select> */}
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={itemGroupList}
                sx={{ width: "100%" }}
                getOptionLabel={(option) => option.label}
                onChange={(event, newValue) => {
                  setSelectGroup(newValue);
                }}
                value={selectGroup}
                renderInput={(params) => (
                  <TextField {...params} label="Select group" size="small" />
                )}
              />
            </div>
            <div
              style={{
                position: "relative",
                zIndex: "9",
                width: "200px",
                marginRight: "15px",
              }}
            >
              {/* <select
            className="form-select"
            aria-label="Default select example"
            onChange={(e) => setSelectCategory(e.target.value)}
            value={selectCategory}
          >
            <option selected>Select category</option>
            {itemCategoryList &&
              itemCategoryList.map((ele) => {
                return (
                  <option value={ele.value} key={ele.value}>
                    {ele.label}
                  </option>
                );
              })}
          </select> */}
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={itemSubGroupList}
                sx={{ width: "100%" }}
                getOptionLabel={(option) => option.label}
                onChange={(event, newValue) => {
                  setSelectSubGroup(newValue);
                }}
                value={selectSubGroup}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Sub Group"
                    size="small"
                  />
                )}
              />
            </div>
            <div
              style={{
                position: "relative",
                zIndex: "9",
                width: "200px",
                marginRight: "15px",
              }}
            >
              {/* <select
            className="form-select"
            aria-label="Default select example"
            onChange={(e) => setSelectCategory(e.target.value)}
            value={selectCategory}
          >
            <option selected>Select category</option>
            {itemCategoryList &&
              itemCategoryList.map((ele) => {
                return (
                  <option value={ele.value} key={ele.value}>
                    {ele.label}
                  </option>
                );
              })}
          </select> */}
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={itemCategoryList}
                sx={{ width: "100%" }}
                value={selectCategory}
                getOptionLabel={(option) => option.label}
                onChange={(event, newValue) => {
                  setSelectCategory(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Select category" size="small" />
                )}
              />
            </div>
            <div
              style={{
                position: "relative",
                zIndex: "9",
                width: "200px",
                marginRight: "15px",
              }}
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={vendorDropDown}
                sx={{ width: "100%" }}
                value={vendor}
                getOptionLabel={(option) => option.label}
                onChange={(event, newValue) => {
                  setVendor(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Select vendor" size="small" />
                )}
              />
            </div>
            <div
              style={{
                position: "relative",
                zIndex: "9",
                width: "200px",
                marginRight: "15px",
              }}
            >
              <input
                className="form-control"
                type="text"
                value={searchValue}
                placeholder="Search Control No.,Tags,Name,"
                onChange={handleSearchCtrl}
              />
            </div>
            {/* Search PO */}
            {/* <div
              style={{
                position: "relative",
                zIndex: "9",
                width: "200px",
                marginRight: "15px",
              }}
            >
              <input
                className="form-control"
                type="text"
                value={searchValue}
                placeholder="Search PO"
                onChange={handleSearchCtrl}
              />
            </div> */}
            <span onClick={handleFilter} style={{ marginRight: "15px" }}>
              <Button className="btn btn-success" buttonName="Search" />
            </span>
            <span onClick={handleCancelSearch}>
              <button type="button" className="btn btn-danger">
                Cancel
              </button>
            </span>
          </div>
        </>
      ) : null}

      <div
        className="budgetTable"
        id="budgetTableCustom"
        style={{
          overflow: "auto",
          marginBottom: "80px",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "20px",
            left: "20px",
          }}
        >
          <div>
            <Button
              onClick={toggleMenu}
              style={{ cursor: "pointer", fontSize: "24px" }}
              buttonName="Apply Actions ⋮ "
              disable={!enableAction}
            />
          </div>
          {/* Dropdown menu */}
          {isDropOpen && (
            <div
              ref={menuRef}
              style={{
                position: "absolute",
                top: "30px",
                left: "0",
                border: "1px solid #ccc",
                backgroundColor: "#fff",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                borderRadius: "4px",
                zIndex: "1000",
              }}
            >
              <div
                onClick={() => {
                  handleBulkEdit();
                  setIsDropOpen(false);
                }}
                style={{
                  padding: "10px",
                  cursor: "pointer",
                  borderBottom: "1px solid #eee",
                }}
              >
                Bulk Edit
              </div>

              <div
                onClick={() => {
                  handleBulkDelete();
                  setIsDropOpen(false);
                }}
                style={{ padding: "10px", cursor: "pointer" }}
              >
                Bulk Delete
              </div>
            </div>
          )}
        </div>

        {/* BUlk DELETE MODAL */}
        <div>
          <Dialog
            open={isModalOpen}
            onClose={handleCancel}
            aria-labelledby="confirmation-dialog-title"
            aria-describedby="confirmation-dialog-description"
            disableEscapeKeyDown
            onBackdropClick={() => {
              handleCancel();
            }}
            sx={{ "& .MuiDialog-paper": { width: "400px", maxWidth: "90%" } }}
          >
            <DialogTitle className="text-lg font-semibold text-gray-700">
              Confirmation
            </DialogTitle>
            <DialogContent>
              <DialogContentText className="text-gray-600">
                {modalMessage}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                buttonName={"Cancel"}
                onClick={() => setIsModalOpen(false)}
                buttonStyle={{ backgroundColor: colors.darkgrey }}
              ></Button>

              {!cantDelete && (
                <Button
                  buttonName={"Ok"}
                  onClick={() => {
                    handleBulkChangeSuccess(bulkSelectedIds);
                    setIsModalOpen(false);
                  }}
                  buttonStyle={{ backgroundColor: colors.primary }}
                ></Button>
              )}
            </DialogActions>
          </Dialog>
        </div>

        <div className="d-flex actionData">
          <div className="d-flex justify-content-end align-items-center gap-2">
            <div>
              {isAuthorizedFunc(CREATE_PURCHASE_ORDER) && (
                <Button
                  style={{
                    color: "rgb(179 179 179)",
                    padding: "9px 28px",
                  }}
                  buttonName="Create PO"
                  onClick={handleCreatePOModal}
                />
              )}
            </div>
            {(isAddRfi || isAddRfq) && (
              <FormDropDown
                isMulti={false}
                value={newDocument.value}
                Options={optionsNewDocument}
                onSelect={handleChangeNewDocument}
                CustomErrorLine={NewDocumentCustomError}
                searchable={false}
                isValidate={false}
                mainContainerStyle={{ zIndex: 3, margin: "0 !important" }}
                selectContainerStyle={{
                  borderRadius: 10,
                  width: "170px",
                  border: "2px solid #cccccc",
                  padding: "2px 0px",
                  margin: "0px 9px",
                  borderBottom: "2px solid #dfdfdf",
                }}
              />
            )}
            {newDocument.value && (
              <span>
                <Button
                  style={{
                    color: "rgb(179 179 179)",
                    padding: "9px 28px",
                  }}
                  buttonName={
                    newDocument.value === "New RFI" ||
                    newDocument.value === "New RFQ"
                      ? `Create ${newDocument.value}`
                      : `${newDocument.value}`
                  }
                  disable={
                    newDocument.value === "New RFI"
                      ? !rfiDisableLogic
                      : // : newDocument.value === "New PO"
                      // ? !rfiDisableLogic
                      newDocument.value === "New RFQ"
                      ? !rfiDisableLogic
                      : newDocument.value === "Image Upload"
                      ? !rfiDisableLogic
                      : false
                  }
                  onClick={handleChangeButton}
                />
              </span>
            )}
            <Tooltip title="Draft Items" placement="top">
              <div
                className="tablebudget"
                style={{ height: "40px !important" }}
              >
                <DraftsIcon
                  style={{
                    fontSize: "21px",
                    margin: "5px 9px 7px",
                    cursor: "pointer",
                    color: "#818181",
                    // cursor: "pointer",
                  }}
                  onClick={draftTable}
                />
              </div>
            </Tooltip>
          </div>

          <div className="d-flex justify-content-end align-items-center">
            <div className="d-flex">
              <Tooltip title="Budget table" placement="top">
                <div
                  className="tablebudget"
                  style={{ height: "40px !important" }}
                >
                  <MdOutlineTableChart
                    style={{
                      fontSize: "21px",
                      margin: "5px 9px 7px",
                      cursor: "pointer",
                      color: "#818181",
                    }}
                    onClick={() => tableDropdown1()}
                  />
                </div>
              </Tooltip>
              <Tooltip title="Status Table" placement="top">
                <div
                  className="statusTable"
                  style={{ height: "40px !important" }}
                >
                  <MdBackupTable
                    style={{
                      fontSize: "21px",
                      margin: "5px 9px 7px",
                      cursor: "pointer",
                      color: "#818181",
                    }}
                    onClick={() => tableDropdown2()}
                  />
                </div>
              </Tooltip>
              <Tooltip title="Chart" placement="top">
                <div
                  className="chartTable"
                  style={{ height: "40px !important", marginRight: "-10px" }}
                >
                  <FaChartPie
                    style={{
                      fontSize: "21px",
                      margin: "5px 9px 7px",
                      cursor: "pointer",
                      color: "#818181",
                    }}
                    onClick={() => chartFunction()}
                  />
                </div>
              </Tooltip>
            </div>
            <div
              className="dropdown"
              style={{
                padding: "7px 9px",
                margin: "0 -31px 0 6px",
                color: "black",
              }}
            >
              <Tooltip title="Download" placement="top">
                <span
                  className="dots_main downloadTable"
                  role="button"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="true"
                  style={{ height: "40px !important" }}
                >
                  {!dropDownList ? (
                    <FileDownloadOutlinedIcon
                      style={{
                        fontSize: "21px",
                        margin: "5px 9px 7px",
                        cursor: "pointer",
                        color: "#818181",
                      }}
                      onClick={handleChangeDrop}
                    />
                  ) : (
                    <CloseOutlinedIcon
                      style={{
                        fontSize: "21px",
                        margin: "5px 9px 7px",
                        cursor: "pointer",
                        color: "#818181",
                      }}
                      onClick={handleChangeDrop}
                    />
                  )}
                </span>
              </Tooltip>

              {dropDownList && (
                <ul
                  aria-labelledby="dropdownMenuLink"
                  style={{
                    position: "absolute",
                    zIndex: 20,
                    left: "-280px",
                    backgroundColor: "white",
                    top: "50px",
                    listStyle: "none",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    padding: "8px",
                  }}
                >
                  {user.loginType != "client" && (
                    <li className="dropdown-item">
                      <CSVLink {...fullTablecsvReport}>FULL TABLE CSV</CSVLink>
                    </li>
                  )}
                  {user.loginType != "client" && (
                    <li className="dropdown-item">
                      <CSVLink {...budgetTablecsvReport}>
                        BUDGET TABLE CSV
                      </CSVLink>
                    </li>
                  )}
                  {user.loginType != "client" && (
                    <li>
                      <a className="dropdown-item">
                        <CSVLink {...varianceTablecsvReport}>
                          VARIANCE TABLE CSV
                        </CSVLink>
                      </a>
                    </li>
                  )}
                  {user.loginType != "client" && (
                    <li>
                      <a className="dropdown-item">
                        <CSVLink {...deliveryTablecsvReport}>
                          DELIVERY TABLE CSV
                        </CSVLink>
                      </a>
                    </li>
                  )}
                  {user.loginType != "client" && (
                    <li>
                      <a className="dropdown-item">
                        <CSVLink {...assetRegisterTablecsvReport}>
                          ASSET REGISTER TABLE CSV
                        </CSVLink>
                      </a>
                    </li>
                  )}

                  <li>
                    {/* <BlobProvider document={<BudgetReport id={budget_id} isBudgetOpen={downldName === "handleBudgetReport"}/>}>
                      {({ url }) => ( */}
                    <a
                      // href={url}
                      target="_blank"
                      className="dropdown-item"
                      name="handleBudgetReport"
                      onClick={handleBudgetReport}
                    >
                      BUDGET REPORT
                    </a>
                    {/* )}
                    </BlobProvider> */}
                  </li>
                  <li>
                    {/* <BlobProvider document={<DeliveryDwnld id={budget_id} isDeliveryOpened={downldName === "deliveryReport"}/>}>
                      {({ url }) => ( */}
                    <a
                      // href={url}
                      target="_blank"
                      className="dropdown-item"
                      name="deliveryReport"
                      onClick={handleDwnldDelivery}
                    >
                      DELIVERY TABLE PDF
                    </a>
                    {/* )}
                    </BlobProvider> */}
                  </li>
                  {user.loginType != "client" && (
                    <li>
                      {/* <BlobProvider
                        document={<VarianceReport id={budget_id} isVarianceOpened={downldName === "varianceReport"}/>}
                      >
                        {({ url }) => ( */}
                      <a
                        // href={url}
                        target="_blank"
                        className="dropdown-item"
                        name="varianceReport"
                        onClick={handleManagement}
                      >
                        VARIANCE TABLE PDF
                      </a>
                      {/* )}
                      </BlobProvider> */}
                    </li>
                  )}
                  {user.loginType != "client" && (
                    <li>
                      {/* <BlobProvider document={<VendorReport id={budget_id} isVendorOpened={downldName === "vendorReport"}/>}>
                        {({ url }) => ( */}
                      <a
                        // href={url}
                        target="_blank"
                        className="dropdown-item"
                        name="vendorReport"
                        onClick={handleManagement}
                      >
                        VENDOR REPORT
                      </a>
                      {/* )}
                      </BlobProvider> */}
                    </li>
                  )}

                  <li>
                    <a
                      className="dropdown-item"
                      onClick={handleDownloadManagement}
                    >
                      MANAGEMENT REPORT
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      name="dynamicReport"
                      onClick={handleManagement}
                    >
                      DYNAMIC SUMMARY REPORT
                    </a>
                  </li>
                  <li>
                    {/* <BlobProvider document={<PoSummery id={budget_id} isPOOpened={downldName === "poSummery"}/>}>
                  {({ url }) => ( */}
                    <a
                      name="poSummery"
                      onClick={handleManagement}
                      // href={url}
                      target="_blank"
                      className="dropdown-item"
                    >
                      PURCHASE ORDERS SUMMARY
                    </a>
                    {/* )}
                      </BlobProvider> */}
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      name="acStatement"
                      onClick={handleManagement}
                    >
                      STATEMENT OF ACCOUNT
                    </a>
                  </li>
                  {user.loginType != "client" && (
                    <li>
                      <a className="dropdown-item">
                        <CSVLink {...statementofAccountTablecsvReport}>
                          STATEMENT OF ACCOUNT (CSV)
                        </CSVLink>
                      </a>
                    </li>
                  )}
                  {user.loginType != "client" && (
                    <li>
                      <a
                        className="dropdown-item"
                        name="statementVendorAc"
                        onClick={handleManagement}
                      >
                        STATEMENT OF ACCOUNT BY VENDOR
                      </a>
                    </li>
                  )}
                  {user.loginType != "client" && (
                    <li>
                      <a className="dropdown-item">
                        <CSVLink {...statementOfAccountVendorTablecsvReport}>
                          STATEMENT OF ACCOUNT BY VENDOR (CSV)
                        </CSVLink>
                      </a>
                    </li>
                  )}
                </ul>
              )}
            </div>
          </div>
        </div>

        {type === "graph" ? (
          <div className="row mt-5 d-flex justify-content-around">
            {scheduleReport.totalItem !== 0 ? (
              <div className="col-md-6 my-3" style={{ width: "30%" }}>
                <h2 className="text-center mb-3">Item Status Chart</h2>
                <Doughnut data={ItemDoughnutdata} />
                <div className="table-responsive mb-3 mb-md-0 mt-3">
                  <table className="table table-borderless report-table ">
                    {ItemProcessData.map((data, index) => {
                      return (
                        <ProcessbarTd
                          label={data.label}
                          percent={data.percent}
                          color={data.color}
                          data={data.data}
                        />
                      );
                    })}
                  </table>
                </div>
              </div>
            ) : (
              <h3>{NotFound}</h3>
            )}
            {scheduleReport.totalItem !== 0 ? (
              <div className="col-md-6 my-3" style={{ width: "25%" }}>
                <h2 className="text-center mb-3">Budget Chart</h2>
                <Doughnut data={Doughnutdata} />
                <div className="table-responsive mb-3 mb-md-0 mt-3">
                  <table className="table table-borderless report-table">
                    {ProcessData.map((data, index) => {
                      return (
                        <ProcessbarTd
                          label={data.label}
                          percent={data.percent}
                          color={data.color}
                          data={data.data}
                        />
                      );
                    })}
                  </table>
                </div>
              </div>
            ) : (
              <h3>{NotFound}</h3>
            )}
          </div>
        ) : type === "item" ? (
          <>
            {!isLoaderr ? (
              <>
                {rseArray.length !== 0 ? (
                  <>
                    <TablePagination
                      showFirstButton
                      showLastButton
                      rowsPerPageOptions={[10, 20, 40, 60, 100, 500]}
                      component="div"
                      count={totalData}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "end",
                        fontStyle: "italic",
                        fontWeight: "bold",
                      }}
                    >
                      {/* show last updated control number */}
                      <p className=" ">
                        Last updated control number: {controlNumber || "N/A"}
                      </p>
                    </div>
                    <BudgetItemTable
                      isAddRfi={isAddRfi}
                      isAddRfq={isAddRfq}
                      isUpdate={isUpdate}
                      isStatus={isStatus}
                      data={rseArray}
                      mapableData={mapableData}
                      searchProp={[
                        "control_number",
                        "tags",
                        "name",
                        "item_code",
                      ]}
                      handleChangeDelete={handleChangeDelete}
                      handleChangeEdit={handleChangeEdit}
                      handleChangeCheck={handleChangeSelectedData}
                      handleSelectAll={handleSelectAll}
                      handleView={handleView}
                      handleFlag={handleFlag}
                      leftDays={leftDays}
                      handleSearchSubmit={handleSearchSubmit}
                      searchValue={searchValues}
                      setSearchValue={setSearchValues}
                      getAlldata={getAlldata}
                      rowsPerPage={rowsPerPage}
                    />
                    <TablePagination
                      showFirstButton
                      showLastButton
                      rowsPerPageOptions={[10, 20, 40, 60, 100, 500]}
                      component="div"
                      count={totalData}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </>
                ) : (
                  <>
                    <NodataFound norecord={false} />
                  </>
                )}
              </>
            ) : (
              <>
                <LoaderComponent />

                {/* {rseArray.length === 0 ? (
                  <LoaderComponent />
                ) : ( */}
                {/* )} */}
              </>
            )}
          </>
        ) : type === "status" ? (
          <BudgetItemTable
            data={rseArray}
            mapableData={mapableData1}
            searchProp={["control_number"]}
            handleChangeDelete={handleChangeDelete}
            handleChangeEdit={handleChangeEdit}
            handleChangeCheck={handleChangeSelectedData}
            handleView={handleView}
          />
        ) : type === "draft" ? (
          <BudgetItemTable
            isUpdate={isUpdate}
            isStatus={isStatus}
            data={draftItem}
            mapableData={mapableData}
            searchProp={["control_number"]}
            handleChangeDelete={handleDraftDelete}
            handleChangeEdit={handleChangeDraftEdit}
          />
        ) : null}
      </div>
    </div>
  );
};

export default SearchBudgetItem;
